// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";
import * as M23$Linear from "./M23.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as V3f$Linear from "./V3f.bs.mjs";
import * as M33f$Linear from "./M33f.bs.mjs";

var zero = M23$Linear.fill(0);

function toM33f(param) {
  return [
          param[0],
          param[1],
          [
            0,
            0,
            1
          ]
        ];
}

function fromM33f(param) {
  return [
          param[0],
          param[1]
        ];
}

function inverse(m) {
  return fromM33f(M33f$Linear.inverse(toM33f(m)));
}

var codec = M23$Linear.codec(Jzon.$$float);

function v2f(mat, vec) {
  return V2f$Linear.asV3f(vec, (function (vec) {
                return V3$Linear.map(toM33f(mat), (function (row) {
                              return V3f$Linear.dot(row, vec);
                            }));
              }));
}

var Cross = {
  v2f: v2f
};

export {
  zero ,
  toM33f ,
  fromM33f ,
  inverse ,
  codec ,
  Cross ,
}
/* zero Not a pure module */
