// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as Form$LumiAuthentication from "./Form.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as Modal$LumiAuthentication from "./Modal.bs.mjs";
import * as Validate$LumiAuthentication from "./Validate.bs.mjs";

function ChangePassword$Error(props) {
  return LumiReact.renderOption(undefined, props.error, (function (err) {
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                        entry: err
                                      })
                                }),
                            className: "notification is-danger"
                          });
              }));
}

var $$Error = {
  make: ChangePassword$Error
};

function ChangePassword$Success(props) {
  var dispatch = props.dispatch;
  return JsxRuntime.jsxs(Modal$LumiAuthentication.make, {
              title: "PhraseChangePassword",
              children: [
                JsxRuntime.jsx("section", {
                      children: JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                  entry: "PhrasePasswordChanged"
                                })
                          }),
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx("button", {
                                        children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                              entry: "PhraseClose"
                                            }),
                                        className: "button is-success",
                                        type: "button",
                                        onClick: (function (param) {
                                            dispatch("Close");
                                          })
                                      }),
                                  className: "control"
                                }),
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var Success = {
  make: ChangePassword$Success
};

function ChangePassword(props) {
  var onClose = props.onClose;
  var match = LumiReactReducer.useReducer((function () {
          return {
                  TAG: "Init",
                  _0: {
                    oldPassword: "",
                    newPassword: "",
                    verifyPassword: "",
                    error: undefined,
                    success: false
                  }
                };
        }), undefined, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var match = param.state;
                              return LumiRequest.resultFutureEffect(API$LumiAuthentication.accountChangePassword(match.oldPassword, match.newPassword), (function () {
                                            dispatch("HandleSuccess");
                                          }), (function (err) {
                                            dispatch({
                                                  TAG: "HandleError",
                                                  _0: err
                                                });
                                          }));
                            })
                        };
              case "HandleSuccess" :
                  return {
                          TAG: "Update",
                          _0: {
                            oldPassword: state.oldPassword,
                            newPassword: state.newPassword,
                            verifyPassword: state.verifyPassword,
                            error: state.error,
                            success: true
                          }
                        };
              case "Close" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              onClose();
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SetOldPassword" :
                  return {
                          TAG: "Update",
                          _0: {
                            oldPassword: action._0,
                            newPassword: state.newPassword,
                            verifyPassword: state.verifyPassword,
                            error: state.error,
                            success: state.success
                          }
                        };
              case "SetNewPassword" :
                  return {
                          TAG: "Update",
                          _0: {
                            oldPassword: state.oldPassword,
                            newPassword: action._0,
                            verifyPassword: state.verifyPassword,
                            error: state.error,
                            success: state.success
                          }
                        };
              case "SetVerifyPassword" :
                  return {
                          TAG: "Update",
                          _0: {
                            oldPassword: state.oldPassword,
                            newPassword: state.newPassword,
                            verifyPassword: action._0,
                            error: state.error,
                            success: state.success
                          }
                        };
              case "HandleError" :
                  var err = action._0;
                  console.error("accountChangePassword failed", err);
                  return {
                          TAG: "Update",
                          _0: {
                            oldPassword: state.oldPassword,
                            newPassword: state.newPassword,
                            verifyPassword: state.verifyPassword,
                            error: I18n$LumiAuthentication.entryFromApiError(err),
                            success: state.success
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var verifyPassword = match$1.verifyPassword;
  var newPassword = match$1.newPassword;
  var oldPassword = match$1.oldPassword;
  var oldPasswordValid = Validate$LumiAuthentication.existingPassword(oldPassword);
  var newPasswordValid = Validate$LumiAuthentication.newPassword(newPassword);
  var verifyPasswordValid = newPassword === verifyPassword;
  var valid = oldPasswordValid && newPasswordValid && verifyPasswordValid;
  if (match$1.success) {
    return JsxRuntime.jsx(ChangePassword$Success, {
                dispatch: dispatch
              });
  } else {
    return JsxRuntime.jsxs(Modal$LumiAuthentication.Form.make, {
                title: "PhraseChangePassword",
                onSubmit: (function (param) {
                    dispatch("Submit");
                  }),
                children: [
                  JsxRuntime.jsxs("section", {
                        children: [
                          JsxRuntime.jsx(ChangePassword$Error, {
                                error: match$1.error
                              }),
                          JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                      entry: "PhraseEnterOldPassword"
                                    })
                              }),
                          JsxRuntime.jsx(Form$LumiAuthentication.ExistingPasswordField.make, {
                                value: oldPassword,
                                valid: oldPasswordValid,
                                onChange: (function (pass) {
                                    dispatch({
                                          TAG: "SetOldPassword",
                                          _0: pass
                                        });
                                  })
                              }),
                          JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                      entry: "PhraseEnterNewPassword"
                                    })
                              }),
                          JsxRuntime.jsx(Form$LumiAuthentication.NewPasswordField.make, {
                                value: newPassword,
                                valid: newPasswordValid,
                                onChange: (function (pass) {
                                    dispatch({
                                          TAG: "SetNewPassword",
                                          _0: pass
                                        });
                                  })
                              }),
                          JsxRuntime.jsx(Form$LumiAuthentication.VerifyPasswordField.make, {
                                value: verifyPassword,
                                valid: verifyPasswordValid,
                                onChange: (function (pass) {
                                    dispatch({
                                          TAG: "SetVerifyPassword",
                                          _0: pass
                                        });
                                  })
                              })
                        ],
                        className: "modal-card-body"
                      }),
                  JsxRuntime.jsx("footer", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx("button", {
                                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                  entry: "PhraseClose"
                                                }),
                                            className: "button",
                                            type: "button",
                                            onClick: (function (param) {
                                                dispatch("Close");
                                              })
                                          }),
                                      className: "control"
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx(Form$LumiAuthentication.Submit.make, {
                                            classList: [
                                              [
                                                "button",
                                                true
                                              ],
                                              [
                                                "is-success",
                                                true
                                              ]
                                            ],
                                            disabled: !valid,
                                            label: "PhraseChangePassword"
                                          }),
                                      className: "control"
                                    })
                              ],
                              className: "field is-grouped is-grouped-right is-flex-1"
                            }),
                        className: "modal-card-foot"
                      })
                ]
              });
  }
}

var T;

var make = ChangePassword;

export {
  T ,
  $$Error ,
  Success ,
  make ,
}
/* LumiReact Not a pure module */
