// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

var values = [
  "ImageCameraDayNightModeColor",
  "ImageCameraDayNightModeBW",
  "ImageCameraDayNightModeAuto",
  "ImageCameraDayNightModeExternalBW",
  "ImageCameraDayNightModeSchedule"
];

var codec = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "ImageCameraDayNightModeColor" :
              tmp = "Color";
              break;
          case "ImageCameraDayNightModeBW" :
              tmp = "BW";
              break;
          case "ImageCameraDayNightModeAuto" :
              tmp = "Auto";
              break;
          case "ImageCameraDayNightModeExternalBW" :
              tmp = "ExternalBW";
              break;
          case "ImageCameraDayNightModeSchedule" :
              tmp = "Schedule";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "Auto" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageCameraDayNightModeAuto"
                                  };
                        case "BW" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageCameraDayNightModeBW"
                                  };
                        case "Color" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageCameraDayNightModeColor"
                                  };
                        case "ExternalBW" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageCameraDayNightModeExternalBW"
                                  };
                        case "Schedule" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageCameraDayNightModeSchedule"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var ImageCameraDayNightMode = {
  values: values,
  codec: codec
};

var values$1 = [
  "ImageWhiteBalanceModeManual",
  "ImageWhiteBalanceModeAWC",
  "ImageWhiteBalanceModeATW",
  "ImageWhiteBalanceModeOutdoor",
  "ImageWhiteBalanceModeIndoor",
  "ImageWhiteBalanceModeMercury",
  "ImageWhiteBalanceModeSodium",
  "ImageWhiteBalanceModeNarrowATW"
];

var codec$1 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "ImageWhiteBalanceModeManual" :
              tmp = "Manual";
              break;
          case "ImageWhiteBalanceModeAWC" :
              tmp = "AWC";
              break;
          case "ImageWhiteBalanceModeATW" :
              tmp = "ATW";
              break;
          case "ImageWhiteBalanceModeOutdoor" :
              tmp = "Outdoor";
              break;
          case "ImageWhiteBalanceModeIndoor" :
              tmp = "Indoor";
              break;
          case "ImageWhiteBalanceModeMercury" :
              tmp = "Mercury";
              break;
          case "ImageWhiteBalanceModeSodium" :
              tmp = "Sodium";
              break;
          case "ImageWhiteBalanceModeNarrowATW" :
              tmp = "NarrowATW";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "ATW" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeATW"
                                  };
                        case "AWC" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeAWC"
                                  };
                        case "Indoor" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeIndoor"
                                  };
                        case "Manual" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeManual"
                                  };
                        case "Mercury" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeMercury"
                                  };
                        case "NarrowATW" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeNarrowATW"
                                  };
                        case "Outdoor" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeOutdoor"
                                  };
                        case "Sodium" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageWhiteBalanceModeSodium"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var ImageWhiteBalanceMode = {
  values: values$1,
  codec: codec$1
};

var values$2 = [
  "ImageFlipRotate0",
  "ImageFlipRotate90",
  "ImageFlipRotate270"
];

var codec$2 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "ImageFlipRotate0" :
              tmp = "0";
              break;
          case "ImageFlipRotate90" :
              tmp = "90";
              break;
          case "ImageFlipRotate270" :
              tmp = "270";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function ($$int) {
                      switch ($$int) {
                        case "0" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageFlipRotate0"
                                  };
                        case "270" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageFlipRotate270"
                                  };
                        case "90" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageFlipRotate90"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      $$int
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var ImageFlipRotate = {
  values: values$2,
  codec: codec$2
};

var values$3 = [
  "ImageSSDRDynamicRangeNarrow",
  "ImageSSDRDynamicRangeWide"
];

var codec$3 = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "ImageSSDRDynamicRangeNarrow" ? "Narrow" : "Wide";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "Narrow" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageSSDRDynamicRangeNarrow"
                                  };
                        case "Wide" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageSSDRDynamicRangeWide"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var ImageSSDRDynamicRange = {
  values: values$3,
  codec: codec$3
};

var values$4 = [
  "ImageIRledModeOff",
  "ImageIRledModeOn",
  "ImageIRledModeSensor",
  "ImageIRledModeSchedule",
  "ImageIRledModeManual",
  "ImageIRledModeAuto",
  "ImageIRledModeAuto1",
  "ImageIRledModeAuto2"
];

var codec$4 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "ImageIRledModeOff" :
              tmp = "Off";
              break;
          case "ImageIRledModeOn" :
              tmp = "On";
              break;
          case "ImageIRledModeSensor" :
              tmp = "Sensor";
              break;
          case "ImageIRledModeSchedule" :
              tmp = "Schedule";
              break;
          case "ImageIRledModeManual" :
              tmp = "Manual";
              break;
          case "ImageIRledModeAuto" :
              tmp = "Auto";
              break;
          case "ImageIRledModeAuto1" :
              tmp = "Auto1";
              break;
          case "ImageIRledModeAuto2" :
              tmp = "Auto2";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "Auto" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeAuto"
                                  };
                        case "Auto1" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeAuto1"
                                  };
                        case "Auto2" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeAuto2"
                                  };
                        case "Manual" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeManual"
                                  };
                        case "Off" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeOff"
                                  };
                        case "On" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeOn"
                                  };
                        case "Schedule" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeSchedule"
                                  };
                        case "Sensor" :
                            return {
                                    TAG: "Ok",
                                    _0: "ImageIRledModeSensor"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var ImageIRledMode = {
  values: values$4,
  $$default: "ImageIRledModeAuto",
  codec: codec$4
};

var values$5 = [
  "SystemDateSyncTypeNTP",
  "SystemDateSyncTypeManual",
  "SystemDateSyncTypeGPS"
];

var codec$5 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "SystemDateSyncTypeNTP" :
              tmp = "NTP";
              break;
          case "SystemDateSyncTypeManual" :
              tmp = "Manual";
              break;
          case "SystemDateSyncTypeGPS" :
              tmp = "GPS";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "GPS" :
                            return {
                                    TAG: "Ok",
                                    _0: "SystemDateSyncTypeGPS"
                                  };
                        case "Manual" :
                            return {
                                    TAG: "Ok",
                                    _0: "SystemDateSyncTypeManual"
                                  };
                        case "NTP" :
                            return {
                                    TAG: "Ok",
                                    _0: "SystemDateSyncTypeNTP"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var SystemDateSyncType = {
  values: values$5,
  codec: codec$5
};

export {
  ImageCameraDayNightMode ,
  ImageWhiteBalanceMode ,
  ImageFlipRotate ,
  ImageSSDRDynamicRange ,
  ImageIRledMode ,
  SystemDateSyncType ,
}
/* codec Not a pure module */
