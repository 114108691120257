// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";

function translate(locale, entry) {
  switch (entry) {
    case "PhraseBackToLogin" :
        if (locale === "en_GB") {
          return "Back to login";
        } else if (locale === "fr_FR") {
          return "Retour à la connexion";
        } else if (locale === "nl_NL") {
          return "Terug naar inloggen";
        } else {
          return "Zurück zur Einloggen";
        }
    case "PhraseChangePassword" :
        if (locale === "en_GB") {
          return "Change password";
        } else if (locale === "fr_FR") {
          return "Changer le mot de passe";
        } else if (locale === "nl_NL") {
          return "Verander wachtwoord";
        } else {
          return "Passwort ändern";
        }
    case "PhraseClose" :
        if (locale === "en_GB") {
          return "Close";
        } else if (locale === "fr_FR") {
          return "Fermer";
        } else if (locale === "nl_NL") {
          return "Sluiten";
        } else {
          return "Schließen";
        }
    case "PhraseEmail" :
        if (locale === "en_GB") {
          return "Email";
        } else if (locale === "fr_FR") {
          return "E-mail";
        } else if (locale === "nl_NL") {
          return "E-mailadres";
        } else {
          return "E-Mail";
        }
    case "PhraseEnterNewPassword" :
        if (locale === "en_GB") {
          return "Enter your new password";
        } else if (locale === "fr_FR") {
          return "Veuillez entrer votre nouveau mot de passe";
        } else if (locale === "nl_NL") {
          return "Voer uw nieuwe wachtwoord in";
        } else {
          return "Bitte geben Sie Ihr neues Passwort ein";
        }
    case "PhraseEnterNewPasswordAgain" :
        if (locale === "en_GB") {
          return "Re-enter your new password";
        } else if (locale === "fr_FR") {
          return "Veuillez ressaisir votre nouveau mot de passe";
        } else if (locale === "nl_NL") {
          return "Voer uw nieuwe wachtwoord nogmaals in";
        } else {
          return "Bitte geben Sie Ihr neues Passwort erneut ein";
        }
    case "PhraseEnterOldPassword" :
        if (locale === "en_GB") {
          return "Enter your old password";
        } else if (locale === "fr_FR") {
          return "Veuillez votre ancien mot de passe";
        } else if (locale === "nl_NL") {
          return "Voer uw oude wachtwoord in";
        } else {
          return "Bitte geben Sie Ihr altes Passwort ein";
        }
    case "PhraseInvalidAPIKey" :
        if (locale === "en_GB") {
          return "Invalid API key";
        } else if (locale === "fr_FR") {
          return "Clé API non valide";
        } else if (locale === "nl_NL") {
          return "Ongeldige API-key";
        } else {
          return "Ungültiger API-Schlüssel";
        }
    case "PhraseLogin" :
        if (locale === "en_GB") {
          return "Log in";
        } else if (locale === "fr_FR") {
          return "S'identifier";
        } else if (locale === "nl_NL") {
          return "Inloggen";
        } else {
          return "Einloggen";
        }
    case "PhraseLoginFailed" :
        if (locale === "en_GB") {
          return "Login failed, are your email and password correct?";
        } else if (locale === "fr_FR") {
          return "La connexion a échoué, votre e-mail et votre mot de passe sont-ils corrects?";
        } else if (locale === "nl_NL") {
          return "Inloggen niet gelukt, zijn uw e-mail en wachtwoord juist?";
        } else {
          return "Einloggen fehlgeschlagen, sind ihre E-Mail und Passwort korrekt?";
        }
    case "PhraseLoginFailedTryAgainLater" :
        if (locale === "en_GB") {
          return "Logging in is currently not possible. Please try again later.";
        } else if (locale === "fr_FR") {
          return "La connexion n'est actuellement pas possible. Réessayez plus tard.";
        } else if (locale === "nl_NL") {
          return "Inloggen is momenteel niet mogelijk. Probeer het later opnieuw.";
        } else {
          return "Eine Anmeldung ist derzeit nicht möglich. Versuchen Sie es später erneut.";
        }
    case "PhraseMailPasswordLinkSent" :
        if (locale === "en_GB") {
          return "A mail has been sent with a password change link.";
        } else if (locale === "fr_FR") {
          return "Un e-mail a été envoyé avec un lien pour changer votre mot de passe.";
        } else if (locale === "nl_NL") {
          return "Er is een e-mail verstuurd met een link om uw wachtwoord te veranderen.";
        } else {
          return "Es wurde eine E-Mail mit einem Link zum Ändern des Passwort gesendet.";
        }
    case "PhraseNotLoggedIn" :
        if (locale === "en_GB") {
          return "You're not logged in!";
        } else if (locale === "fr_FR") {
          return "Vous n'êtes pas connecté";
        } else if (locale === "nl_NL") {
          return "U bent niet ingelogd";
        } else {
          return "Sie sind nicht eingeloggt!";
        }
    case "PhrasePassword" :
        if (locale === "en_GB") {
          return "Password";
        } else if (locale === "fr_FR") {
          return "Mot de passe";
        } else if (locale === "nl_NL") {
          return "Wachtwoord";
        } else {
          return "Passwort";
        }
    case "PhrasePasswordChanged" :
        if (locale === "en_GB") {
          return "Your password has been changed successfully.";
        } else if (locale === "fr_FR") {
          return "Votre mot de passe a été changé avec succès.";
        } else if (locale === "nl_NL") {
          return "Uw wachtwoord is succesvol veranderd.";
        } else {
          return "Ihr Passwort wurde erfolgreich geändert.";
        }
    case "PhrasePasswordForgotten" :
        if (locale === "en_GB") {
          return "I forgot my password";
        } else if (locale === "fr_FR") {
          return "J'ai oublié mon mot de passe.";
        } else if (locale === "nl_NL") {
          return "Ik ben mijn wachtwoord vergeten";
        } else {
          return "Ich habe mein Passwort vergessen.";
        }
    case "PhraseRetry" :
        if (locale === "en_GB") {
          return "Retry";
        } else if (locale === "fr_FR") {
          return "Réessayez";
        } else if (locale === "nl_NL") {
          return "Opnieuw proberen";
        } else {
          return "Wiederholen";
        }
    case "PhraseSendPasswordResetMail" :
        if (locale === "en_GB") {
          return "Send password reset email";
        } else if (locale === "fr_FR") {
          return "Envoyez un e-mail pour réinitialiser votre mot de passe.";
        } else if (locale === "nl_NL") {
          return "Stuur wachtwoord reset e-mail";
        } else {
          return "Senden Sie eine E-Mail, um Ihr Passwort zurückzusetzen.";
        }
    case "PhraseToLogin" :
        if (locale === "en_GB") {
          return "To login";
        } else if (locale === "fr_FR") {
          return "Retour à la connexion.";
        } else if (locale === "nl_NL") {
          return "Naar inloggen";
        } else {
          return "Zur Einloggen";
        }
    case "PhraseWaitingTimeExpired" :
        if (locale === "en_GB") {
          return "The link in the password reset email has expired. Please request a password reset again.";
        } else if (locale === "fr_FR") {
          return "Le lien dans l'e-mail de réinitialisation du mot de passe a expiré. Demandez à nouveau une réinitialisation de mot de passe.";
        } else if (locale === "nl_NL") {
          return "De link in de wachtwoordresete-mail is verlopen. Vraag opnieuw een wachtwoordreset aan.";
        } else {
          return "Der Link in der E-Mail zum Zurücksetzen des Passworts ist abgelaufen. Fordern Sie erneut eine Kennwortzurücksetzung an.";
        }
    
  }
}

function translate$1(locale, entry) {
  return translate(locale, entry);
}

var T = Translation$LumiI18n.MakeProvider({
      translate: translate$1
    });

function entryFromApiError(err) {
  if (typeof err !== "object") {
    if (err === "NoXSRFTokenCookie") {
      return "PhraseNotLoggedIn";
    } else {
      return "PhraseLoginFailedTryAgainLater";
    }
  } else if (err.TAG === "ServerError" && err.status === 401) {
    return "PhraseNotLoggedIn";
  } else {
    return "PhraseLoginFailedTryAgainLater";
  }
}

export {
  translate ,
  T ,
  entryFromApiError ,
}
/* T Not a pure module */
