// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";

function fill(v) {
  return [
          v,
          v
        ];
}

function map(param, f) {
  return [
          f(param[0]),
          f(param[1])
        ];
}

function reduce(param, init, f) {
  return Core__Array.reduce([
              param[0],
              param[1]
            ], init, f);
}

function zipWith(f, param, param$1) {
  return [
          f(param[0], param$1[0]),
          f(param[1], param$1[1])
        ];
}

function codec(elementCodec) {
  var arrayCodec = Jzon.array(elementCodec);
  return Jzon.custom((function (param) {
                return Jzon.encodeWith([
                            param[0],
                            param[1]
                          ], arrayCodec);
              }), (function (json) {
                var match = Jzon.decodeWith(json, arrayCodec);
                if (match.TAG === "Ok") {
                  var match$1 = match._0;
                  if (match$1.length === 2) {
                    var a = match$1[0];
                    var b = match$1[1];
                    return {
                            TAG: "Ok",
                            _0: [
                              a,
                              b
                            ]
                          };
                  }
                  
                }
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonType",
                          VAL: [
                            [],
                            "array[2]",
                            json
                          ]
                        }
                      };
              }));
}

export {
  fill ,
  map ,
  reduce ,
  zipWith ,
  codec ,
}
/* No side effect */
