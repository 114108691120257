// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2i$Linear from "lumi-linear/lib/es6/src/V2i.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Rect$Linear from "lumi-linear/lib/es6/src/Rect.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as LumiDrawCommand from "lumi-draw-command/lib/es6/src/LumiDrawCommand.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Types$LumiDocuments from "./Types.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";
import * as Display__SignSettings$LumiDocuments from "./Display__SignSettings.bs.mjs";

var codec = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          if (v === "DisplayModeAvailabilityColorFreeFull") {
            return [
                    "FreeFull",
                    undefined
                  ];
          } else {
            return [
                    "FreeBusyFull",
                    undefined
                  ];
          }
        } else {
          return [
                  "Fixed",
                  Jzon.encodeWith(v._0, Types$LumiDocuments.Color.codec)
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "Fixed" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, Types$LumiDocuments.Color.codec), (function (color) {
                              return {
                                      TAG: "DisplayModeAvailabilityColorFixed",
                                      _0: color
                                    };
                            }));
              }
              break;
          case "FreeBusyFull" :
              if (pair[1] === undefined) {
                return {
                        TAG: "Ok",
                        _0: "DisplayModeAvailabilityColorFreeBusyFull"
                      };
              }
              break;
          case "FreeFull" :
              if (pair[1] === undefined) {
                return {
                        TAG: "Ok",
                        _0: "DisplayModeAvailabilityColorFreeFull"
                      };
              }
              break;
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    other
                  ]
                }
              };
      }));

var DisplayModeAvailabilityColor = {
  codec: codec
};

var codec$1 = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          return [
                  "Number",
                  undefined
                ];
        } else {
          return [
                  "Text",
                  Jzon.encodeWith(v._0, Jzon.string)
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "Number" :
              if (pair[1] === undefined) {
                return {
                        TAG: "Ok",
                        _0: "DisplayModeFullRepresentationNumber"
                      };
              }
              break;
          case "Text" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (text) {
                              return {
                                      TAG: "DisplayModeFullRepresentationText",
                                      _0: text
                                    };
                            }));
              }
              break;
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    other
                  ]
                }
              };
      }));

var DisplayModeFullRepresentation = {
  codec: codec$1
};

var codec$2 = Jzon.object3((function (param) {
        return [
                param.font,
                param.hAlign,
                param.vAlign
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  font: param[0],
                  hAlign: param[1],
                  vAlign: param[2]
                }
              };
      }), Jzon.field("font", Jzon.string), Jzon.field("hAlign", LumiDrawCommand.HAlign.codec), Jzon.field("vAlign", LumiDrawCommand.VAlign.codec));

var DisplayModeTextOptions = {
  codec: codec$2
};

var codec$3 = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "DisplayModeArrowStyleStraight" ? "Straight" : "Angled";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "Angled" :
                            return {
                                    TAG: "Ok",
                                    _0: "DisplayModeArrowStyleAngled"
                                  };
                        case "Straight" :
                            return {
                                    TAG: "Ok",
                                    _0: "DisplayModeArrowStyleStraight"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var DisplayModeArrowStyle = {
  codec: codec$3
};

var textCodec = LumiJzon.tuple3(Jzon.string, Types$LumiDocuments.Color.codec, codec$2);

var arrowCodec = LumiJzon.tuple3(Types$LumiDocuments.CardinalDirection.codec, Types$LumiDocuments.Color.codec, codec$3);

var codec$4 = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          return [
                  "Blank",
                  undefined
                ];
        }
        switch (v.TAG) {
          case "DisplayModeStaticFloodFill" :
              return [
                      "FloodFill",
                      Jzon.encodeWith(v._0, Types$LumiDocuments.Color.codec)
                    ];
          case "DisplayModeStaticText" :
              return [
                      "Text",
                      Jzon.encodeWith([
                            v._0,
                            v._1,
                            v._2
                          ], textCodec)
                    ];
          case "DisplayModeStaticImage" :
              return [
                      "Image",
                      Jzon.encodeWith(v._0, Jzon.string)
                    ];
          case "DisplayModeStaticArrow" :
              return [
                      "Arrow",
                      Jzon.encodeWith([
                            v._0,
                            v._1,
                            v._2
                          ], arrowCodec)
                    ];
          
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "Arrow" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, arrowCodec), (function (param) {
                              return {
                                      TAG: "DisplayModeStaticArrow",
                                      _0: param[0],
                                      _1: param[1],
                                      _2: param[2]
                                    };
                            }));
              }
              break;
          case "Blank" :
              if (pair[1] === undefined) {
                return {
                        TAG: "Ok",
                        _0: "DisplayModeStaticBlank"
                      };
              }
              break;
          case "FloodFill" :
              var json$1 = pair[1];
              if (json$1 !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json$1, Types$LumiDocuments.Color.codec), (function (color) {
                              return {
                                      TAG: "DisplayModeStaticFloodFill",
                                      _0: color
                                    };
                            }));
              }
              break;
          case "Image" :
              var json$2 = pair[1];
              if (json$2 !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json$2, Jzon.string), (function (path) {
                              return {
                                      TAG: "DisplayModeStaticImage",
                                      _0: path
                                    };
                            }));
              }
              break;
          case "Text" :
              var json$3 = pair[1];
              if (json$3 !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json$3, textCodec), (function (param) {
                              return {
                                      TAG: "DisplayModeStaticText",
                                      _0: param[0],
                                      _1: param[1],
                                      _2: param[2]
                                    };
                            }));
              }
              break;
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    other
                  ]
                }
              };
      }));

var DisplayModeStatic = {
  codec: codec$4
};

var numFreeCodec = LumiJzon.tuple3(codec, codec$1, codec$2);

var arrowCodec$1 = LumiJzon.tuple2(codec, codec$3);

var codec$5 = LumiJzon.taggedVariant((function (v) {
        switch (v.TAG) {
          case "DisplayModeGroupNumFree" :
              return [
                      "NumFree",
                      Jzon.encodeWith([
                            v._0,
                            v._1,
                            v._2
                          ], numFreeCodec)
                    ];
          case "DisplayModeGroupOpen" :
              return [
                      "Open",
                      Jzon.encodeWith(v._0, codec$2)
                    ];
          case "DisplayModeGroupFreeFull" :
              return [
                      "FreeFull",
                      Jzon.encodeWith(v._0, codec$2)
                    ];
          case "DisplayModeGroupFloodFill" :
              return [
                      "FloodFill",
                      Jzon.encodeWith(v._0, codec)
                    ];
          case "DisplayModeGroupArrow" :
              return [
                      "Arrow",
                      Jzon.encodeWith([
                            v._0,
                            v._1
                          ], arrowCodec$1)
                    ];
          case "DisplayModeGroupAlternative" :
              return [
                      "Alternative",
                      Jzon.encodeWith(v._0, codec$2)
                    ];
          case "DisplayModeGroupFacilityName" :
              return [
                      "FacilityName",
                      Jzon.encodeWith(v._0, codec$2)
                    ];
          case "DisplayModeGroupPopUpNum" :
              return [
                      "PopUpNum",
                      Jzon.encodeWith(v._0, codec$2)
                    ];
          
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "Alternative" :
              return Core__Result.map(Jzon.decodeWith(json, codec$2), (function (textOptions) {
                            return {
                                    TAG: "DisplayModeGroupAlternative",
                                    _0: textOptions
                                  };
                          }));
          case "Arrow" :
              return Core__Result.map(Jzon.decodeWith(json, arrowCodec$1), (function (param) {
                            return {
                                    TAG: "DisplayModeGroupArrow",
                                    _0: param[0],
                                    _1: param[1]
                                  };
                          }));
          case "FacilityName" :
              return Core__Result.map(Jzon.decodeWith(json, codec$2), (function (textOptions) {
                            return {
                                    TAG: "DisplayModeGroupFacilityName",
                                    _0: textOptions
                                  };
                          }));
          case "FloodFill" :
              return Core__Result.map(Jzon.decodeWith(json, codec), (function (availabilityColor) {
                            return {
                                    TAG: "DisplayModeGroupFloodFill",
                                    _0: availabilityColor
                                  };
                          }));
          case "FreeFull" :
              return Core__Result.map(Jzon.decodeWith(json, codec$2), (function (textOptions) {
                            return {
                                    TAG: "DisplayModeGroupFreeFull",
                                    _0: textOptions
                                  };
                          }));
          case "NumFree" :
              return Core__Result.map(Jzon.decodeWith(json, numFreeCodec), (function (param) {
                            return {
                                    TAG: "DisplayModeGroupNumFree",
                                    _0: param[0],
                                    _1: param[1],
                                    _2: param[2]
                                  };
                          }));
          case "Open" :
              return Core__Result.map(Jzon.decodeWith(json, codec$2), (function (textOptions) {
                            return {
                                    TAG: "DisplayModeGroupOpen",
                                    _0: textOptions
                                  };
                          }));
          case "PopUpNum" :
              return Core__Result.map(Jzon.decodeWith(json, codec$2), (function (textOptions) {
                            return {
                                    TAG: "DisplayModeGroupPopUpNum",
                                    _0: textOptions
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var DisplayModeGroup = {
  codec: codec$5
};

var codec$6 = LumiJzon.taggedVariant((function (v) {
        if (v.TAG === "DisplayModeForGroupStatic") {
          return [
                  "Static",
                  Jzon.encodeWith(v._0, codec$4)
                ];
        } else {
          return [
                  "Group",
                  Jzon.encodeWith(v._0, codec$5)
                ];
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "Group" :
              return Core__Result.map(Jzon.decodeWith(json, codec$5), (function (groupMode) {
                            return {
                                    TAG: "DisplayModeForGroupGroup",
                                    _0: groupMode
                                  };
                          }));
          case "Static" :
              return Core__Result.map(Jzon.decodeWith(json, codec$4), (function (staticMode) {
                            return {
                                    TAG: "DisplayModeForGroupStatic",
                                    _0: staticMode
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var DisplayModeForGroup = {
  codec: codec$6
};

var codec$7 = Jzon.object2((function (param) {
        return [
                param.direction,
                param.isPopUp
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  direction: param[0],
                  isPopUp: param[1]
                }
              };
      }), Jzon.field("direction", Jzon.nullable(Types$LumiDocuments.CardinalDirection.codec)), Jzon.field("isPopUp", Jzon.bool));

var DisplayModeGroupOptions = {
  codec: codec$7
};

var lazyDisplayModeGroupAlternativesCodec = {
  LAZY_DONE: false,
  VAL: (function () {
      return Jzon.object5((function (param) {
                    return [
                            param.referenceGroup,
                            param.inputs,
                            param.includeFull,
                            param.mode,
                            param.fallbackMode
                          ];
                  }), (function (param) {
                    return {
                            TAG: "Ok",
                            _0: {
                              referenceGroup: param[0],
                              inputs: param[1],
                              includeFull: param[2],
                              mode: param[3],
                              fallbackMode: param[4]
                            }
                          };
                  }), Jzon.field("referenceGroup", Jzon.nullable(SensorGroups$LumiStaticData.GroupFullId.codec)), Jzon.field("inputs", Jzon.array(LumiJzon.tuple2(SensorGroups$LumiStaticData.GroupFullId.codec, codec$7))), Jzon.field("includeFull", Jzon.bool), Jzon.field("mode", codec$6), Jzon.field("fallbackMode", CamlinternalLazy.force(lazyDisplayModeCodec)));
    })
};

var lazyDisplayModeSplitVerticallyCodec = {
  LAZY_DONE: false,
  VAL: (function () {
      return LumiJzon.tuple3(Jzon.$$int, CamlinternalLazy.force(lazyDisplayModeCodec), CamlinternalLazy.force(lazyDisplayModeCodec));
    })
};

var lazyDisplayModeCodec = {
  LAZY_DONE: false,
  VAL: (function () {
      var groupCodec = LumiJzon.tuple3(SensorGroups$LumiStaticData.GroupFullId.codec, codec$7, codec$5);
      return LumiJzon.taggedVariant((function (v) {
                    switch (v.TAG) {
                      case "DisplayModeStatic" :
                          return [
                                  "Static",
                                  Jzon.encodeWith(v._0, codec$4)
                                ];
                      case "DisplayModeGroup" :
                          return [
                                  "Group",
                                  Jzon.encodeWith([
                                        v._0,
                                        v._1,
                                        v._2
                                      ], groupCodec)
                                ];
                      case "DisplayModeFirstAvailableGroup" :
                          return [
                                  "FirstAvailableGroup",
                                  Jzon.encodeWith(v._0, CamlinternalLazy.force(lazyDisplayModeGroupAlternativesCodec))
                                ];
                      case "DisplayModeSplitVertically" :
                          return [
                                  "SplitVertically",
                                  Jzon.encodeWith([
                                        v._0,
                                        v._1,
                                        v._2
                                      ], CamlinternalLazy.force(lazyDisplayModeSplitVerticallyCodec))
                                ];
                      
                    }
                  }), (function (param) {
                    var json = param[1];
                    var tag = param[0];
                    switch (tag) {
                      case "FirstAvailableGroup" :
                          return Core__Result.map(Jzon.decodeWith(json, CamlinternalLazy.force(lazyDisplayModeGroupAlternativesCodec)), (function (displayModeGroupAlternatives) {
                                        return {
                                                TAG: "DisplayModeFirstAvailableGroup",
                                                _0: displayModeGroupAlternatives
                                              };
                                      }));
                      case "Group" :
                          return Core__Result.map(Jzon.decodeWith(json, groupCodec), (function (param) {
                                        return {
                                                TAG: "DisplayModeGroup",
                                                _0: param[0],
                                                _1: param[1],
                                                _2: param[2]
                                              };
                                      }));
                      case "SplitVertically" :
                          return Core__Result.map(Jzon.decodeWith(json, CamlinternalLazy.force(lazyDisplayModeSplitVerticallyCodec)), (function (param) {
                                        return {
                                                TAG: "DisplayModeSplitVertically",
                                                _0: param[0],
                                                _1: param[1],
                                                _2: param[2]
                                              };
                                      }));
                      case "Static" :
                          return Core__Result.map(Jzon.decodeWith(json, codec$4), (function (staticMode) {
                                        return {
                                                TAG: "DisplayModeStatic",
                                                _0: staticMode
                                              };
                                      }));
                      default:
                        return {
                                TAG: "Error",
                                _0: {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    tag
                                  ]
                                }
                              };
                    }
                  }));
    })
};

var Internal = {
  lazyDisplayModeGroupAlternativesCodec: lazyDisplayModeGroupAlternativesCodec,
  lazyDisplayModeSplitVerticallyCodec: lazyDisplayModeSplitVerticallyCodec,
  lazyDisplayModeCodec: lazyDisplayModeCodec
};

var codec$8 = CamlinternalLazy.force(lazyDisplayModeCodec);

var DisplayMode = {
  codec: codec$8
};

var codec$9 = Jzon.object4((function (param) {
        return [
                param.name,
                param.rect,
                param.mode,
                param.preferredGroupNameLabels
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  rect: param[1],
                  mode: param[2],
                  preferredGroupNameLabels: param[3]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("rect", Recti$Linear.codec), Jzon.field("mode", codec$8), Jzon.field("preferredGroupNameLabels", Jzon.array(Jzon.string)));

function Display$DisplayRegion$SVG(props) {
  var match = props.region;
  var match$1 = match.rect;
  var match$2 = V2$Linear.map(match$1.pos, (function (prim) {
          return prim.toString();
        }));
  var match$3 = V2$Linear.map(match$1.size, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("rect", {
              children: JsxRuntime.jsx("title", {
                    children: match.name
                  }),
              height: match$3[1],
              width: match$3[0],
              fill: "transparent",
              stroke: "pink",
              strokeWidth: "1",
              vectorEffect: "non-scaling-stroke",
              x: match$2[0],
              y: match$2[1]
            });
}

var SVG = {
  make: Display$DisplayRegion$SVG
};

var DisplayRegion = {
  codec: codec$9,
  SVG: SVG
};

function Display$DisplayController(props) {
  var displayController = props.displayController;
  var tmp;
  tmp = displayController.TAG === "DisplayControllerLumiSign" ? displayController._0 : "Colorlight";
  return JsxRuntime.jsx("span", {
              children: tmp,
              className: "is-family-monospace"
            });
}

var codec$10 = LumiJzon.taggedVariant((function (v) {
        if (v.TAG === "DisplayControllerLumiSign") {
          return [
                  "LumiSign",
                  Jzon.encodeWith(v._0, Jzon.string)
                ];
        } else {
          return [
                  "Colorlight",
                  Jzon.encodeWith(v._0, Document$LumiStore.Key.codec())
                ];
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "Colorlight" :
              return Core__Result.map(Jzon.decodeWith(json, Document$LumiStore.Key.codec()), (function (colorlightKey) {
                            return {
                                    TAG: "DisplayControllerColorlight",
                                    _0: colorlightKey
                                  };
                          }));
          case "LumiSign" :
              return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (name) {
                            return {
                                    TAG: "DisplayControllerLumiSign",
                                    _0: name
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var DisplayController = {
  make: Display$DisplayController,
  codec: codec$10
};

var codec$11 = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          return [
                  "Central",
                  undefined
                ];
        } else {
          return [
                  "FacilityServer",
                  Jzon.encodeWith(v._0, Document$LumiStore.Key.codec())
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "Central" :
              if (pair[1] === undefined) {
                return {
                        TAG: "Ok",
                        _0: "DisplayUpdaterCentral"
                      };
              }
              break;
          case "FacilityServer" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, Document$LumiStore.Key.codec()), (function (facServerKey) {
                              return {
                                      TAG: "DisplayUpdaterFacilityServer",
                                      _0: facServerKey
                                    };
                            }));
              }
              break;
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    other
                  ]
                }
              };
      }));

var DisplayUpdater = {
  codec: codec$11
};

var codec$12 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "DisplayPlanned" :
              tmp = "Planned";
              break;
          case "DisplayOperational" :
              tmp = "Operational";
              break;
          case "DisplayArchived" :
              tmp = "Archived";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (x) {
                      switch (x) {
                        case "Archived" :
                            return {
                                    TAG: "Ok",
                                    _0: "DisplayArchived"
                                  };
                        case "Operational" :
                            return {
                                    TAG: "Ok",
                                    _0: "DisplayOperational"
                                  };
                        case "Planned" :
                            return {
                                    TAG: "Ok",
                                    _0: "DisplayPlanned"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      x
                                    ]
                                  }
                                };
                      }
                    }));
      }));

function toString(value) {
  switch (value) {
    case "DisplayPlanned" :
        return "Planned";
    case "DisplayOperational" :
        return "Operational";
    case "DisplayArchived" :
        return "Archived";
    
  }
}

function Display$DisplayOperationalState(props) {
  var value = props.value;
  var tmp;
  switch (value) {
    case "DisplayPlanned" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "person-digging"
                  }),
              className: "icon has-text-warning"
            });
        break;
    case "DisplayOperational" :
        tmp = null;
        break;
    case "DisplayArchived" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "broom"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    
  }
  return JsxRuntime.jsxs("span", {
              children: [
                tmp,
                JsxRuntime.jsx("span", {
                      children: toString(value)
                    })
              ],
              className: "icon-text"
            });
}

var DisplayOperationalState = {
  codec: codec$12,
  toString: toString,
  make: Display$DisplayOperationalState
};

var codec$13 = Jzon.object7((function (param) {
        return [
                param.name,
                param.position,
                param.regions,
                param.controller,
                param.updatedBy,
                param.signSettings,
                param.operationalState
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  position: param[1],
                  regions: param[2],
                  controller: param[3],
                  updatedBy: param[4],
                  signSettings: param[5],
                  operationalState: param[6]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("position", Jzon.nullable(Types$LumiDocuments.GeoPosition.codec)), Jzon.field("regions", Jzon.array(codec$9)), Jzon.field("controller", Jzon.nullable(codec$10)), Jzon.field("updatedBy", codec$11), Jzon.field("signSettings", Display__SignSettings$LumiDocuments.SignSettings.codec), Jzon.field("operationalState", codec$12));

function Display$Display$SVG(props) {
  var __children = props.children;
  var __classList = props.classList;
  var match = props.display;
  var signSettings = match.signSettings;
  var panels = signSettings.panels;
  var classList = __classList !== undefined ? __classList : [];
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var frame = Core__Option.mapOr(Display__SignSettings$LumiDocuments.SignSettings.panelSize(signSettings), Recti$Linear.zero, (function (panelSize) {
          var h = panelSize[1];
          var w = panelSize[0];
          return Recti$Linear.boundingBoxFromPoints((function (__x) {
                          return Caml_splice_call.spliceObjApply([], "concat", [__x]);
                        })((function (__x) {
                                return Caml_splice_call.spliceObjApply([], "concat", [__x]);
                              })(Belt_MapString.valuesToArray(panels)).map(function (param) {
                              var pos_0 = param.x;
                              var pos_1 = param.y;
                              var pos = [
                                pos_0,
                                pos_1
                              ];
                              var rotSize;
                              switch (param.rotation) {
                                case "RightAngle_0" :
                                case "RightAngle_180" :
                                    rotSize = panelSize;
                                    break;
                                case "RightAngle_90" :
                                case "RightAngle_270" :
                                    rotSize = [
                                      h,
                                      w
                                    ];
                                    break;
                                
                              }
                              return [
                                      pos,
                                      V2i$Linear.plus(pos, rotSize)
                                    ];
                            })));
        }));
  var className = LumiReact.classList(classList);
  var aabb = Rectf$Linear.scaleCenter(Rect$Linear.map(frame, (function (prim) {
              return prim;
            })), 1.02);
  var match$1 = V2$Linear.map(frame.pos, (function (prim) {
          return prim.toString();
        }));
  var match$2 = V2$Linear.map(frame.size, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsxs("svg", {
              children: [
                JsxRuntime.jsx("rect", {
                      height: match$2[1],
                      width: match$2[0],
                      fill: "black",
                      stroke: "black",
                      strokeWidth: "1",
                      vectorEffect: "non-scaling-stroke",
                      x: match$1[0],
                      y: match$1[1]
                    }),
                children,
                match.regions.map(function (region, idx) {
                      return JsxRuntime.jsx(Display$DisplayRegion$SVG, {
                                  region: region
                                }, idx.toString());
                    })
              ],
              className: className,
              preserveAspectRatio: "xMidYMid meet",
              viewBox: LumiSVG.ViewBox.Float.fromRect(aabb)
            });
}

var SVG$1 = {
  make: Display$Display$SVG
};

var Display = {
  codec: codec$13,
  SVG: SVG$1
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "sign"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "display",
      codec: codec$13,
      icon: icon
    });

export {
  DisplayModeAvailabilityColor ,
  DisplayModeFullRepresentation ,
  DisplayModeTextOptions ,
  DisplayModeArrowStyle ,
  DisplayModeStatic ,
  DisplayModeGroup ,
  DisplayModeForGroup ,
  DisplayModeGroupOptions ,
  Internal ,
  DisplayMode ,
  DisplayRegion ,
  DisplayController ,
  DisplayUpdater ,
  DisplayOperationalState ,
  Display ,
  Schema ,
}
/* codec Not a pure module */
