// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Core__Date from "@rescript/core/lib/es6/src/Core__Date.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function since(date) {
  return Date.now() - date.getTime();
}

function dateWireFormat(date) {
  return date.toISOString().substring(0, 10);
}

function cmp(a, b) {
  return Core__Date.compare(a, b) | 0;
}

var Cmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function toString(date) {
  return date.toISOString().substring(0, 10);
}

function fromString(str) {
  var date = new Date(str);
  if (isNaN(date.getTime())) {
    return {
            TAG: "Error",
            _0: "Invalid date: " + str
          };
  } else {
    return {
            TAG: "Ok",
            _0: date
          };
  }
}

var codec = Jzon.custom((function (date) {
        return Jzon.encodeWith(toString(date), Jzon.string);
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (prim) {
                      return new Date(prim);
                    }));
      }));

var $$Date$1 = {
  toString: toString,
  fromString: fromString,
  codec: codec
};

function toString$1(prim) {
  return prim.toISOString();
}

function fromString$1(str) {
  var date = new Date(str);
  if (isNaN(date.getTime())) {
    return {
            TAG: "Error",
            _0: "Invalid date: " + str
          };
  } else {
    return {
            TAG: "Ok",
            _0: date
          };
  }
}

var codec$1 = Jzon.custom((function (date) {
        return Jzon.encodeWith(date.toISOString(), Jzon.string);
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (prim) {
                      return new Date(prim);
                    }));
      }));

var DateTime = {
  toString: toString$1,
  fromString: fromString$1,
  codec: codec$1
};

function first(dates) {
  if (dates.length !== 0) {
    return Caml_option.some(new Date(Caml_splice_call.spliceApply(Math.min, [dates.map(function (prim) {
                              return prim.getTime();
                            })])));
  }
  
}

function last(dates) {
  if (dates.length !== 0) {
    return Caml_option.some(new Date(Caml_splice_call.spliceApply(Math.max, [dates.map(function (prim) {
                              return prim.getTime();
                            })])));
  }
  
}

function toSeconds(duration) {
  if (duration.TAG === "Seconds") {
    return duration._0;
  } else {
    return duration._0 / 1e6;
  }
}

function fromSeconds(s) {
  return {
          TAG: "Seconds",
          _0: s
        };
}

function toMicroseconds(duration) {
  if (duration.TAG === "Seconds") {
    return duration._0 * 1e6;
  } else {
    return duration._0;
  }
}

function fromMicroseconds(us) {
  return {
          TAG: "Microseconds",
          _0: us
        };
}

var seconds = Jzon.custom((function (duration) {
        return Jzon.encodeWith(toSeconds(duration), Jzon.$$float);
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, Jzon.$$float), fromSeconds);
      }));

var microseconds = Jzon.custom((function (duration) {
        return Jzon.encodeWith(toMicroseconds(duration), Jzon.$$float);
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, Jzon.$$float), fromMicroseconds);
      }));

var Codecs = {
  seconds: seconds,
  microseconds: microseconds
};

var Duration = {
  toSeconds: toSeconds,
  fromSeconds: fromSeconds,
  toMicroseconds: toMicroseconds,
  fromMicroseconds: fromMicroseconds,
  Codecs: Codecs
};

var JsDate;

export {
  JsDate ,
  since ,
  dateWireFormat ,
  Cmp ,
  $$Date$1 as $$Date,
  DateTime ,
  first ,
  last ,
  Duration ,
}
/* Cmp Not a pure module */
