// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as React from "react";
import * as LocalStorage from "lumi-rescript/lib/es6/src/LocalStorage.bs.mjs";

var localStorageKey = "lumi-bulma/forceDarkMode";

function useForceDarkMode() {
  var match = React.useState(function () {
        return LocalStorage.getItemJSON(localStorageKey, Jzon.bool, false);
      });
  var forceDarkMode = match[0];
  React.useEffect((function () {
          var $$class = "prefers-scheme-dark";
          var classList = document.documentElement.classList;
          if (forceDarkMode) {
            classList.add($$class);
          } else {
            classList.remove($$class);
          }
          LocalStorage.setItemJSON(localStorageKey, Jzon.bool, forceDarkMode);
        }), [forceDarkMode]);
  return [
          forceDarkMode,
          match[1]
        ];
}

export {
  localStorageKey ,
  useForceDarkMode ,
}
/* react Not a pure module */
