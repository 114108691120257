// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spinner from "lumi-rescript/lib/es6/src/Spinner.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as RescriptReactRouter from "@rescript/react/lib/es6/src/RescriptReactRouter.bs.mjs";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as APIKeyLogin$LumiAuthentication from "./APIKeyLogin.bs.mjs";
import * as GlobalState$LumiAuthentication from "./GlobalState.bs.mjs";
import * as PasswordLogin$LumiAuthentication from "./PasswordLogin.bs.mjs";
import * as PasswordReset$LumiAuthentication from "./PasswordReset.bs.mjs";
import * as PasswordForgotten$LumiAuthentication from "./PasswordForgotten.bs.mjs";

import '../../../src/css/main.scss'
;

function checkServerError(error, toAuth) {
  if (typeof error !== "object") {
    if (error === "NoXSRFTokenCookie") {
      return toAuth("HandleUnauthorized");
    } else {
      return ;
    }
  } else if (error.TAG === "ServerError" && error.status === 401) {
    return toAuth("HandleUnauthorized");
  } else {
    return ;
  }
}

var default_key = Document$LumiStore.Key.fromString("");

var default_rev = {
  revNum: Document$LumiStore.RevNum.beforeFirst,
  createTime: new Date(0),
  body: {
    deleted: false,
    labelsHash: undefined,
    labels: undefined,
    ext: {
      owner: Document$LumiStore.Key.fromString(""),
      author: Document$LumiStore.Key.fromString("")
    },
    contents: {
      email: undefined,
      name: "Placeholder account"
    }
  }
};

var $$default = {
  key: default_key,
  rev: default_rev
};

var Account = LumiReact.Context.Make({
      $$default: $$default
    });

function $$default$1(param) {
  
}

var Dispatch = LumiReact.Context.Make({
      $$default: $$default$1
    });

function Barrier(props) {
  var __forceAPIKey = props.forceAPIKey;
  var forceAPIKey = __forceAPIKey !== undefined ? __forceAPIKey : false;
  var match = LumiReactReducer.useReducer((function () {
          var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
          var apiKey = APIKeyLogin$LumiAuthentication.apiKeyFromUrl(url);
          if (forceAPIKey) {
            return {
                    TAG: "Init",
                    _0: {
                      TAG: "APIKeyLogin",
                      apiKey: Core__Option.getOr(apiKey, "")
                    }
                  };
          }
          var passwordResetLock = PasswordReset$LumiAuthentication.passwordResetLockFromUrl(url);
          if (passwordResetLock !== undefined) {
            return {
                    TAG: "Init",
                    _0: {
                      TAG: "PasswordReset",
                      accountId: passwordResetLock[0],
                      secret: passwordResetLock[1]
                    }
                  };
          } else if (apiKey !== undefined) {
            return {
                    TAG: "Init",
                    _0: {
                      TAG: "APIKeyLogin",
                      apiKey: apiKey
                    }
                  };
          } else {
            return {
                    TAG: "InitWithEffect",
                    _0: "GettingAccount",
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        return LumiRequest.resultFutureEffect(API$LumiAuthentication.getAccount(), (function (account) {
                                      dispatch({
                                            TAG: "Authenticated",
                                            _0: account
                                          });
                                    }), (function (err) {
                                      dispatch({
                                            TAG: "GetAccountFailed",
                                            _0: err
                                          });
                                    }));
                      })
                  };
          }
        }), undefined, (function (_state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "PerformLogout" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              return LumiRequest.resultFutureEffect(API$LumiAuthentication.cookieLogout(), (function () {
                                            dispatch("LogoutSucceeded");
                                          }), (function (prim) {
                                            console.error(prim);
                                          }));
                            })
                        };
              case "LogoutSucceeded" :
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "PasswordLogin",
                            initialEmail: "",
                            initialError: undefined
                          }
                        };
              case "HandleUnauthorized" :
                  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
                  var apiKey = APIKeyLogin$LumiAuthentication.apiKeyFromUrl(url);
                  if (forceAPIKey) {
                    return {
                            TAG: "Update",
                            _0: {
                              TAG: "APIKeyLogin",
                              apiKey: Core__Option.getOr(apiKey, "")
                            }
                          };
                  } else if (apiKey !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              TAG: "APIKeyLogin",
                              apiKey: apiKey
                            }
                          };
                  } else {
                    return {
                            TAG: "UpdateWithEffect",
                            _0: {
                              TAG: "PasswordLogin",
                              initialEmail: "",
                              initialError: "PhraseNotLoggedIn"
                            },
                            _1: (function (param) {
                                GlobalState$LumiAuthentication.deleteXSRFTokenCookie();
                              })
                          };
                  }
              
            }
          } else {
            switch (action.TAG) {
              case "Authenticated" :
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "LoggedIn",
                            _0: action._0
                          }
                        };
              case "GetAccountFailed" :
                  var err = action._0;
                  console.log("getAccount returned an error: ", err);
                  var tmp;
                  tmp = typeof err !== "object" ? (
                      err === "NoXSRFTokenCookie" ? undefined : I18n$LumiAuthentication.entryFromApiError(err)
                    ) : (
                      err.TAG === "ServerError" && err.status === 401 ? undefined : I18n$LumiAuthentication.entryFromApiError(err)
                    );
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "PasswordLogin",
                            initialEmail: "",
                            initialError: tmp
                          }
                        };
              case "ShowPasswordLogin" :
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "PasswordLogin",
                            initialEmail: action.initialEmail,
                            initialError: action.initialError
                          }
                        };
              case "ShowPasswordForgotten" :
                  return {
                          TAG: "Update",
                          _0: {
                            TAG: "PasswordForgotten",
                            initialEmail: action.initialEmail,
                            initialError: action.initialError
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  if (typeof state !== "object") {
    return JsxRuntime.jsx(Spinner.make, {});
  }
  switch (state.TAG) {
    case "APIKeyLogin" :
        return JsxRuntime.jsx(APIKeyLogin$LumiAuthentication.make, {
                    apiKey: state.apiKey,
                    forceAPIKey: forceAPIKey,
                    toParent: dispatch
                  });
    case "PasswordLogin" :
        return JsxRuntime.jsx(PasswordLogin$LumiAuthentication.make, {
                    initialEmail: state.initialEmail,
                    initialError: state.initialError,
                    toParent: dispatch
                  });
    case "PasswordForgotten" :
        return JsxRuntime.jsx(PasswordForgotten$LumiAuthentication.make, {
                    initialEmail: state.initialEmail,
                    initialError: state.initialError,
                    toParent: dispatch
                  });
    case "PasswordReset" :
        return JsxRuntime.jsx(PasswordReset$LumiAuthentication.make, {
                    accountId: state.accountId,
                    secret: state.secret,
                    toParent: dispatch
                  });
    case "LoggedIn" :
        return JsxRuntime.jsx(Dispatch.Provider.make, {
                    value: dispatch,
                    children: JsxRuntime.jsx(Account.Provider.make, {
                          value: state._0,
                          children: props.children
                        })
                  });
    
  }
}

var make = Barrier;

export {
  checkServerError ,
  Account ,
  Dispatch ,
  make ,
}
/*  Not a pure module */
