// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";

var codec = Jzon.object1((function (param) {
        return param.name;
      }), (function (name) {
        return {
                TAG: "Ok",
                _0: {
                  name: name
                }
              };
      }), Jzon.field("name", Jzon.string));

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "building"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "organisation",
      codec: codec,
      icon: icon
    });

var Organisation = {
  codec: codec,
  Schema: Schema
};

var codec$1 = Jzon.object2((function (param) {
        return [
                param.email,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  email: param[0],
                  name: param[1]
                }
              };
      }), Jzon.field("email", Jzon.nullable(Jzon.string)), Jzon.field("name", Jzon.string));

function documentName$1(param) {
  return param.name;
}

var icon$1 = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "user"
    });

var Schema$1 = Schema$LumiStore.Make({
      documentName: documentName$1,
      tag: "account",
      codec: codec$1,
      icon: icon$1
    });

var Account = {
  codec: codec$1,
  Schema: Schema$1
};

var codec$2 = Jzon.custom((function (lockType) {
        var tmp;
        switch (lockType) {
          case "PasswordLock" :
              tmp = "PasswordLock";
              break;
          case "PasswordResetLock" :
              tmp = "PasswordResetLock";
              break;
          case "APIKeyLock" :
              tmp = "APIKeyLock";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "APIKeyLock" :
                            return {
                                    TAG: "Ok",
                                    _0: "APIKeyLock"
                                  };
                        case "PasswordLock" :
                            return {
                                    TAG: "Ok",
                                    _0: "PasswordLock"
                                  };
                        case "PasswordResetLock" :
                            return {
                                    TAG: "Ok",
                                    _0: "PasswordResetLock"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var LockType = {
  codec: codec$2
};

var codec$3 = Jzon.object4((function (param) {
        return [
                param.account,
                param.type_,
                param.secret,
                param.expiresAt
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  account: param[0],
                  type_: param[1],
                  secret: param[2],
                  expiresAt: param[3]
                }
              };
      }), Jzon.field("account", Document$LumiStore.Key.codec()), Jzon.field("type", codec$2), Jzon.field("secret", Jzon.string), Jzon.field("expiresAt", Jzon.nullable(LumiDate.DateTime.codec)));

function documentName$2(param) {
  
}

var icon$2 = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "lock"
    });

var Schema$2 = Schema$LumiStore.Make({
      documentName: documentName$2,
      tag: "lock",
      codec: codec$3,
      icon: icon$2
    });

var Lock = {
  LockType: LockType,
  codec: codec$3,
  Schema: Schema$2
};

var codec$4 = Jzon.object2((function (param) {
        return [
                param.owner,
                param.author
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  owner: param[0],
                  author: param[1]
                }
              };
      }), Jzon.field("_extOwner", Document$LumiStore.Key.codec()), Jzon.field("_extAuthor", Document$LumiStore.Key.codec()));

var LumiExt = {
  codec: codec$4
};

export {
  Organisation ,
  Account ,
  Lock ,
  LumiExt ,
}
/* codec Not a pure module */
