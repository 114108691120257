// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2$Linear from "./V2.bs.mjs";
import * as M22$Linear from "./M22.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";

var zero = M22$Linear.fill(0);

function scalar(value) {
  return [
          [
            value,
            0
          ],
          [
            0,
            value
          ]
        ];
}

var identity_0 = [
  1,
  0
];

var identity_1 = [
  0,
  1
];

var identity = [
  identity_0,
  identity_1
];

function scale(m, s) {
  return M22$Linear.map(m, (function (e) {
                return e * s;
              }));
}

function determinant(param) {
  var match = param[1];
  var match$1 = param[0];
  return match$1[0] * match[1] - match$1[1] * match[0];
}

function inverse(mat) {
  var match = mat[1];
  var match$1 = mat[0];
  return scale([
              [
                match[1],
                - match$1[1]
              ],
              [
                - match[0],
                match$1[0]
              ]
            ], 1 / determinant(mat));
}

var codec = M22$Linear.codec(Jzon.$$float);

function v2f(a, b) {
  return V2$Linear.map(a, (function (row) {
                return V2f$Linear.dot(row, b);
              }));
}

function m22f(a, b) {
  var bcols = M22$Linear.transpose(b);
  return V2$Linear.map(a, (function (row) {
                return V2$Linear.map(bcols, (function (col) {
                              return V2f$Linear.dot(row, col);
                            }));
              }));
}

var Cross = {
  v2f: v2f,
  m22f: m22f
};

function rotation(angle) {
  return [
          [
            Math.cos(angle),
            -1 * Math.sin(angle)
          ],
          [
            Math.sin(angle),
            Math.cos(angle)
          ]
        ];
}

var Transformation = {
  rotation: rotation
};

export {
  zero ,
  scalar ,
  identity ,
  scale ,
  determinant ,
  inverse ,
  codec ,
  Cross ,
  Transformation ,
}
/* zero Not a pure module */
