// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";

function cmp(param, param$1) {
  var match = param$1.groupFullId;
  var match$1 = param.groupFullId;
  var c = Caml.int_compare(match$1[0], match[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml.int_compare(match$1[1], match[1]);
  }
}

var NamedGroupCmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function aggregate(rows) {
  var len = rows.length;
  if (len !== 1) {
    if (len !== 0) {
      return Core__Array.reduce(rows.slice(1), rows[0], (function (a, b) {
                    var totalSamples = a.samples + b.samples | 0;
                    return {
                            freeMin: Math.min(a.freeMin, b.freeMin),
                            freeMax: Math.max(a.freeMax, b.freeMax),
                            freeMean: (a.freeMean * a.samples + b.freeMean * b.samples) / totalSamples,
                            occupiedMin: Math.min(a.occupiedMin, b.occupiedMin),
                            occupiedMax: Math.max(a.occupiedMax, b.occupiedMax),
                            occupiedMean: (a.occupiedMean * a.samples + b.occupiedMean * b.samples) / totalSamples,
                            errorMin: Math.min(a.errorMin, b.errorMin),
                            errorMax: Math.max(a.errorMax, b.errorMax),
                            errorMean: (a.errorMean * a.samples + b.errorMean * b.samples) / totalSamples,
                            inflow: a.inflow + b.inflow | 0,
                            outflow: a.outflow + b.outflow | 0,
                            samples: totalSamples
                          };
                  }));
    } else {
      return ;
    }
  } else {
    return rows[0];
  }
}

var namedGroup = Jzon.object2((function (param) {
        return [
                param.groupFullId,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  groupFullId: param[0],
                  name: param[1]
                }
              };
      }), Jzon.field("groupFullId", SensorGroups$LumiStaticData.GroupFullId.codec), Jzon.field("name", Jzon.string));

var sensorGroupStateHistoryAggregate = Jzon.object12((function (param) {
        return [
                param.freeMin,
                param.freeMax,
                param.freeMean,
                param.occupiedMin,
                param.occupiedMax,
                param.occupiedMean,
                param.errorMin,
                param.errorMax,
                param.errorMean,
                param.inflow,
                param.outflow,
                param.samples
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  freeMin: param[0],
                  freeMax: param[1],
                  freeMean: param[2],
                  occupiedMin: param[3],
                  occupiedMax: param[4],
                  occupiedMean: param[5],
                  errorMin: param[6],
                  errorMax: param[7],
                  errorMean: param[8],
                  inflow: param[9],
                  outflow: param[10],
                  samples: param[11]
                }
              };
      }), Jzon.field("freeMin", Jzon.$$int), Jzon.field("freeMax", Jzon.$$int), Jzon.field("freeMean", Jzon.$$float), Jzon.field("occupiedMin", Jzon.$$int), Jzon.field("occupiedMax", Jzon.$$int), Jzon.field("occupiedMean", Jzon.$$float), Jzon.field("errorMin", Jzon.$$int), Jzon.field("errorMax", Jzon.$$int), Jzon.field("errorMean", Jzon.$$float), Jzon.field("inflow", Jzon.$$int), Jzon.field("outflow", Jzon.$$int), Jzon.field("samples", Jzon.$$int));

var sensorGroupStateHistoryAggregateRow = LumiJzon.tuple2(LumiDate.DateTime.codec, sensorGroupStateHistoryAggregate);

var Codecs = {
  namedGroup: namedGroup,
  sensorGroupStateHistoryAggregate: sensorGroupStateHistoryAggregate,
  sensorGroupStateHistoryAggregateRow: sensorGroupStateHistoryAggregateRow
};

export {
  NamedGroupCmp ,
  aggregate ,
  Codecs ,
}
/* NamedGroupCmp Not a pure module */
