// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2$Linear from "./V2.bs.mjs";

function map(param, f) {
  return {
          pos: V2$Linear.map(param.pos, f),
          size: V2$Linear.map(param.size, f)
        };
}

function codec(elementCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.pos,
                        param.size
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          pos: param[0],
                          size: param[1]
                        }
                      };
              }), Jzon.field("pos", V2$Linear.codec(elementCodec)), Jzon.field("size", V2$Linear.codec(elementCodec)));
}

export {
  map ,
  codec ,
}
/* No side effect */
