// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Documents$LumiAuthentication from "lumi-authentication/lib/es6/src/Documents.bs.mjs";

function documentCodec(contentCodec) {
  return Document$LumiStore.$$Document.codec(Documents$LumiAuthentication.LumiExt.codec, contentCodec);
}

export {
  documentCodec ,
}
/* Document-LumiStore Not a pure module */
