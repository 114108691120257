// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Locale from "date-fns/locale";

function toString(locale) {
  if (locale === "en_GB") {
    return "en_GB";
  } else if (locale === "fr_FR") {
    return "fr_FR";
  } else if (locale === "nl_NL") {
    return "nl_NL";
  } else {
    return "de_DE";
  }
}

function fromString(str) {
  switch (str) {
    case "de" :
    case "de-DE" :
    case "de_DE" :
        return "de_DE";
    case "en" :
    case "en-GB" :
    case "en_GB" :
        return "en_GB";
    case "fr" :
    case "fr-FR" :
    case "fr_FR" :
        return "fr_FR";
    case "nl" :
    case "nl-NL" :
    case "nl_NL" :
        return "nl_NL";
    default:
      return ;
  }
}

function toIntlLocale(locale) {
  if (locale === "en_GB") {
    return "en-GB";
  } else if (locale === "fr_FR") {
    return "fr-FR";
  } else if (locale === "nl_NL") {
    return "nl-NL";
  } else {
    return "de-DE";
  }
}

function toDateFnsLocale(locale) {
  if (locale === "en_GB") {
    return Locale.enGB;
  } else if (locale === "fr_FR") {
    return Locale.fr;
  } else if (locale === "nl_NL") {
    return Locale.nl;
  } else {
    return Locale.de;
  }
}

function name(locale) {
  if (locale === "en_GB") {
    return "English (UK)";
  } else if (locale === "fr_FR") {
    return "Français (FR)";
  } else if (locale === "nl_NL") {
    return "Nederlands (NL)";
  } else {
    return "Deutsch (DE)";
  }
}

var include = LumiReact.Context.Make({
      $$default: "en_GB"
    });

var context = include.context;

var useContext = include.useContext;

var Provider = include.Provider;

export {
  toString ,
  fromString ,
  toIntlLocale ,
  toDateFnsLocale ,
  name ,
  context ,
  useContext ,
  Provider ,
}
/* include Not a pure module */
