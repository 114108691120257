// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";
import * as V3f$Linear from "./V3f.bs.mjs";

var zero = V3$Linear.fill(0);

function plus(param, param$1) {
  return [
          param[0] + param$1[0] | 0,
          param[1] + param$1[1] | 0,
          param[2] + param$1[2] | 0
        ];
}

function minus(param, param$1) {
  return [
          param[0] - param$1[0] | 0,
          param[1] - param$1[1] | 0,
          param[2] - param$1[2] | 0
        ];
}

function scale(vec, factor) {
  return V3$Linear.map(vec, (function (v) {
                return Math.imul(v, factor);
              }));
}

function dot(param, param$1) {
  return (Math.imul(param[0], param$1[0]) + Math.imul(param[1], param$1[1]) | 0) + Math.imul(param[2], param$1[2]) | 0;
}

function length(vec) {
  return Math.sqrt(dot(vec, vec));
}

function angle(a, b) {
  var af = V3$Linear.map(a, (function (prim) {
          return prim;
        }));
  var bf = V3$Linear.map(b, (function (prim) {
          return prim;
        }));
  return V3f$Linear.angle(af, bf);
}

var codec = V3$Linear.codec(Jzon.$$int);

export {
  zero ,
  plus ,
  minus ,
  scale ,
  dot ,
  length ,
  angle ,
  codec ,
}
/* zero Not a pure module */
