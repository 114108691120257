// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2i$Linear from "lumi-linear/lib/es6/src/V2i.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Core__String from "@rescript/core/lib/es6/src/Core__String.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var codec = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "HAlignLeft" :
              tmp = "HAlignLeft";
              break;
          case "HAlignCenter" :
              tmp = "HAlignCenter";
              break;
          case "HAlignRight" :
              tmp = "HAlignRight";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "HAlignCenter" :
                            return {
                                    TAG: "Ok",
                                    _0: "HAlignCenter"
                                  };
                        case "HAlignLeft" :
                            return {
                                    TAG: "Ok",
                                    _0: "HAlignLeft"
                                  };
                        case "HAlignRight" :
                            return {
                                    TAG: "Ok",
                                    _0: "HAlignRight"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var HAlign = {
  codec: codec
};

var codec$1 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "VAlignTop" :
              tmp = "VAlignTop";
              break;
          case "VAlignCenter" :
              tmp = "VAlignCenter";
              break;
          case "VAlignBottom" :
              tmp = "VAlignBottom";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "VAlignBottom" :
                            return {
                                    TAG: "Ok",
                                    _0: "VAlignBottom"
                                  };
                        case "VAlignCenter" :
                            return {
                                    TAG: "Ok",
                                    _0: "VAlignCenter"
                                  };
                        case "VAlignTop" :
                            return {
                                    TAG: "Ok",
                                    _0: "VAlignTop"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var VAlign = {
  codec: codec$1
};

var codec$2 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "TransformCrop" :
              tmp = "TransformCrop";
              break;
          case "TransformScale" :
              tmp = "TransformScale";
              break;
          case "TransformThumbnail" :
              tmp = "TransformThumbnail";
              break;
          case "TransformFit" :
              tmp = "TransformFit";
              break;
          case "TransformTile" :
              tmp = "TransformTile";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "TransformCrop" :
                            return {
                                    TAG: "Ok",
                                    _0: "TransformCrop"
                                  };
                        case "TransformFit" :
                            return {
                                    TAG: "Ok",
                                    _0: "TransformFit"
                                  };
                        case "TransformScale" :
                            return {
                                    TAG: "Ok",
                                    _0: "TransformScale"
                                  };
                        case "TransformThumbnail" :
                            return {
                                    TAG: "Ok",
                                    _0: "TransformThumbnail"
                                  };
                        case "TransformTile" :
                            return {
                                    TAG: "Ok",
                                    _0: "TransformTile"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var Transform = {
  codec: codec$2
};

var codec$3 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "FilterNearest" :
              tmp = "FilterNearest";
              break;
          case "FilterBilinear" :
              tmp = "FilterBilinear";
              break;
          case "FilterBicubic" :
              tmp = "FilterBicubic";
              break;
          case "FilterAntiAlias" :
              tmp = "FilterAntiAlias";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "FilterAntiAlias" :
                            return {
                                    TAG: "Ok",
                                    _0: "FilterAntiAlias"
                                  };
                        case "FilterBicubic" :
                            return {
                                    TAG: "Ok",
                                    _0: "FilterBicubic"
                                  };
                        case "FilterBilinear" :
                            return {
                                    TAG: "Ok",
                                    _0: "FilterBilinear"
                                  };
                        case "FilterNearest" :
                            return {
                                    TAG: "Ok",
                                    _0: "FilterNearest"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var Filter = {
  codec: codec$3
};

var codec$4 = Jzon.object5((function (param) {
        return [
                param.text,
                param.font,
                param.hAlign,
                param.vAlign,
                param.fill
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  text: param[0],
                  font: param[1],
                  hAlign: param[2],
                  vAlign: param[3],
                  fill: param[4]
                }
              };
      }), Jzon.field("text", Jzon.string), Jzon.field("font", Jzon.string), Jzon.field("hAlign", codec), Jzon.field("vAlign", codec$1), Jzon.field("fill", Jzon.string));

var DrawText = {
  codec: codec$4
};

var codec$5 = Jzon.object3((function (param) {
        return [
                param.transform,
                param.filter,
                param.image
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  transform: param[0],
                  filter: param[1],
                  image: param[2]
                }
              };
      }), Jzon.field("transform", codec$2), Jzon.field("filter", codec$3), Jzon.field("image", Jzon.string));

var DrawImage = {
  codec: codec$5
};

var codec$6 = Jzon.object3((function (param) {
        return [
                param.points,
                param.fill,
                param.outline
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  points: param[0],
                  fill: param[1],
                  outline: param[2]
                }
              };
      }), Jzon.field("points", Jzon.array(LumiJzon.tuple2(Jzon.$$int, Jzon.$$int))), Jzon.field("fill", Jzon.string), Jzon.field("outline", Jzon.string));

var DrawPolygon = {
  codec: codec$6
};

var codec$7 = LumiJzon.taggedVariant((function (value) {
        switch (value.TAG) {
          case "DrawInRectText" :
              return [
                      "DrawInRectText",
                      Jzon.encodeWith(value._0, codec$4)
                    ];
          case "DrawInRectImage" :
              return [
                      "DrawInRectImage",
                      Jzon.encodeWith(value._0, codec$5)
                    ];
          case "DrawInRectPolygon" :
              return [
                      "DrawInRectPolygon",
                      Jzon.encodeWith(value._0, codec$6)
                    ];
          
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "DrawInRectImage" :
              return Core__Result.map(Jzon.decodeWith(json, codec$5), (function (drawImage) {
                            return {
                                    TAG: "DrawInRectImage",
                                    _0: drawImage
                                  };
                          }));
          case "DrawInRectPolygon" :
              return Core__Result.map(Jzon.decodeWith(json, codec$6), (function (drawPolygon) {
                            return {
                                    TAG: "DrawInRectPolygon",
                                    _0: drawPolygon
                                  };
                          }));
          case "DrawInRectText" :
              return Core__Result.map(Jzon.decodeWith(json, codec$4), (function (drawText) {
                            return {
                                    TAG: "DrawInRectText",
                                    _0: drawText
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var DrawInRect = {
  codec: codec$7
};

var codec$8 = Jzon.object4((function (param) {
        return [
                param.pos,
                param.size,
                param.rotation,
                param.draw
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  pos: param[0],
                  size: param[1],
                  rotation: param[2],
                  draw: param[3]
                }
              };
      }), Jzon.field("pos", LumiJzon.tuple2(Jzon.$$int, Jzon.$$int)), Jzon.field("size", LumiJzon.tuple2(Jzon.$$int, Jzon.$$int)), Jzon.field("rotation", Jzon.$$int), Jzon.field("draw", codec$7));

function LumiDrawCommand$LumiDrawCommand$SVG(props) {
  var match = props.command;
  var draw = match.draw;
  var size = match.size;
  var pos = match.pos;
  switch (draw.TAG) {
    case "DrawInRectText" :
        var match$1 = draw._0;
        var font = match$1.font;
        var match$2 = V2$Linear.map(pos, (function (prim) {
                return prim;
              }));
        var fY = match$2[1];
        var fX = match$2[0];
        var match$3 = V2$Linear.map(size, (function (prim) {
                return prim;
              }));
        var fHeight = match$3[1];
        var fWidth = match$3[0];
        var match$4;
        switch (match$1.hAlign) {
          case "HAlignLeft" :
              match$4 = [
                fX,
                "start"
              ];
              break;
          case "HAlignCenter" :
              match$4 = [
                fX + fWidth * 0.5,
                "middle"
              ];
              break;
          case "HAlignRight" :
              match$4 = [
                fX + fWidth,
                "end"
              ];
              break;
          
        }
        var match$5;
        switch (match$1.vAlign) {
          case "VAlignTop" :
              match$5 = [
                fY,
                "text-top"
              ];
              break;
          case "VAlignCenter" :
              match$5 = [
                fY + fHeight * 0.5,
                "middle"
              ];
              break;
          case "VAlignBottom" :
              match$5 = [
                fY + fHeight,
                "text-bottom"
              ];
              break;
          
        }
        var match$6 = V2$Linear.map([
              match$4[0],
              match$5[0]
            ], (function (prim) {
                return prim.toString();
              }));
        var idx = Core__String.lastIndexOfOpt(font, ":");
        var match$7 = idx !== undefined ? [
            font.slice(0, idx),
            Core__Int.fromString(10, font.slice(idx + 1 | 0))
          ] : [
            font,
            undefined
          ];
        var fontSize = Core__Option.getOr(match$7[1], 12).toString() + "px";
        var match$8;
        switch (match$7[0]) {
          case "DejaVuSansMono-Bold" :
              match$8 = [
                "'DejaVu Sans Mono Bold', monospace",
                "bold"
              ];
              break;
          case "Sofia_Pro_Bold" :
              match$8 = [
                "'Sofia Pro Bold', sans-serif",
                "bold"
              ];
              break;
          case "selawkb" :
              match$8 = [
                "'Selawik Bold', 'Segoe UI', sans-serif",
                "bold"
              ];
              break;
          case "selawksb" :
              match$8 = [
                "'Selawik Semibold', 'Segoe UI', sans-serif",
                "600"
              ];
              break;
          case "sq" :
              match$8 = [
                "sq, monospace",
                "normal"
              ];
              break;
          case "sqb" :
              match$8 = [
                "sqBold, monospace",
                "bold"
              ];
              break;
          default:
            match$8 = [
              "default",
              "normal"
            ];
        }
        return JsxRuntime.jsx("text", {
                    children: match$1.text,
                    dominantBaseline: match$5[1],
                    fill: match$1.fill,
                    fontFamily: match$8[0],
                    fontSize: fontSize,
                    fontWeight: match$8[1],
                    stroke: "none",
                    textAnchor: match$4[1],
                    x: match$6[0],
                    y: match$6[1]
                  });
    case "DrawInRectImage" :
        var image = draw._0.image;
        var match$9 = V2$Linear.map(pos, (function (prim) {
                return prim.toString();
              }));
        var match$10 = V2$Linear.map(size, (function (prim) {
                return prim.toString();
              }));
        var href = "/static-display-data/images/" + image;
        return JsxRuntime.jsx("image", {
                    alt: image,
                    height: match$10[1],
                    href: href,
                    width: match$10[0],
                    x: match$9[0],
                    y: match$9[1]
                  });
    case "DrawInRectPolygon" :
        var match$11 = draw._0;
        var points = match$11.points.map(function (extra) {
                  return V2i$Linear.plus(pos, extra);
                }).map(function (p) {
                var match = V2$Linear.map(p, (function (prim) {
                        return prim.toString();
                      }));
                return match[0] + "," + match[1];
              }).join(" ");
        return JsxRuntime.jsx("polygon", {
                    fill: match$11.fill,
                    points: points,
                    stroke: match$11.outline,
                    strokeWidth: "1"
                  });
    
  }
}

var SVG = {
  make: LumiDrawCommand$LumiDrawCommand$SVG
};

var LumiDrawCommand = {
  codec: codec$8,
  SVG: SVG
};

function LumiDrawCommand$Tags(props) {
  return JsxRuntime.jsx("div", {
              children: props.commands.map(function (param, idx) {
                    var draw = param.draw;
                    switch (draw.TAG) {
                      case "DrawInRectText" :
                          var match = draw._0;
                          return JsxRuntime.jsx("span", {
                                      children: match.text,
                                      className: "tag is-black has-text-weight-semibold",
                                      style: {
                                        color: match.fill
                                      }
                                    }, idx.toString());
                      case "DrawInRectImage" :
                          return JsxRuntime.jsxs("span", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                    name: "image"
                                                  }),
                                              className: "icon"
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: draw._0.image
                                            })
                                      ],
                                      className: "tag is-primary is-family-monospace"
                                    }, idx.toString());
                      case "DrawInRectPolygon" :
                          return JsxRuntime.jsx("span", {
                                      children: "\xa0",
                                      className: "tag",
                                      style: {
                                        backgroundColor: draw._0.fill
                                      }
                                    }, idx.toString());
                      
                    }
                  }),
              className: "tags"
            });
}

var Tags = {
  make: LumiDrawCommand$Tags
};

export {
  HAlign ,
  VAlign ,
  Transform ,
  Filter ,
  DrawText ,
  DrawImage ,
  DrawPolygon ,
  DrawInRect ,
  LumiDrawCommand ,
  Tags ,
}
/* codec Not a pure module */
