// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as V3i$Linear from "lumi-linear/lib/es6/src/V3i.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var Color = {
  codec: V3i$Linear.codec
};

var pattern = /^[0-9a-z]{8}$/;

function toString(str) {
  return str._0;
}

function fromString(str) {
  if (pattern.test(str)) {
    return {
            TAG: "Address",
            _0: str
          };
  }
  
}

var codec = Jzon.custom((function (str) {
        return Jzon.encodeWith(str._0, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      return LumiOption.okOr(fromString(str), {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    str
                                  ]
                                });
                    }));
      }));

function Types$Address(props) {
  return JsxRuntime.jsx("span", {
              children: props.address._0,
              className: "is-family-monospace"
            });
}

function cmp(a, b) {
  return Caml.string_compare(a._0, b._0);
}

var Cmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var Address = {
  pattern: pattern,
  toString: toString,
  fromString: fromString,
  codec: codec,
  make: Types$Address,
  Cmp: Cmp
};

function toString$1(value) {
  switch (value) {
    case "PortA" :
        return "a";
    case "PortB" :
        return "b";
    case "PortC" :
        return "c";
    case "PortD" :
        return "d";
    
  }
}

function fromString$1(str) {
  switch (str) {
    case "a" :
        return "PortA";
    case "b" :
        return "PortB";
    case "c" :
        return "PortC";
    case "d" :
        return "PortD";
    default:
      return ;
  }
}

var codec$1 = Jzon.custom((function (value) {
        return Jzon.encodeWith(toString$1(value), Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      return LumiOption.okOr(fromString$1(str), {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    str
                                  ]
                                });
                    }));
      }));

var CommsChannel = {
  toString: toString$1,
  fromString: fromString$1,
  codec: codec$1
};

var codec$2 = Jzon.object3((function (param) {
        return [
                param.bridge,
                param.channel,
                param.indicator
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  bridge: param[0],
                  channel: param[1],
                  indicator: param[2]
                }
              };
      }), Jzon.field("bridge", codec), Jzon.field("channel", codec$1), Jzon.field("indicator", codec));

var FullAddress = {
  codec: codec$2
};

export {
  Color ,
  Address ,
  CommsChannel ,
  FullAddress ,
}
/* codec Not a pure module */
