// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as LumiString from "lumi-rescript/lib/es6/src/LumiString.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Rect$Linear from "lumi-linear/lib/es6/src/Rect.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";
import * as Panel$Dashboard from "./Panel.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as DateTime$LumiI18n from "lumi-i18n/lib/es6/src/DateTime.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as RightAngle$LumiDocuments from "lumi-documents/lib/es6/src/Types/RightAngle.bs.mjs";
import * as Stallingsnet$LumiCentral from "lumi-central/lib/es6/src/Stallingsnet.bs.mjs";
import * as SensorState$LumiDocuments from "lumi-documents/lib/es6/src/SensorState.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";
import * as AbandonedVehicleReport$Dashboard from "./AbandonedVehicleReport.bs.mjs";

function stripS3Signature(x) {
  return x.split("?")[0];
}

function stripOccupationSnapshotImageURL(snap) {
  return {
          snapshot: snap.snapshot,
          imageUrl: stripS3Signature(snap.imageUrl),
          placeAABB: snap.placeAABB
        };
}

function memo(__x) {
  return React.memo(__x, (function (nextProps, currentProps) {
                return Caml_obj.equal(Core__Option.map(nextProps.maybeSnapshot, stripOccupationSnapshotImageURL), Core__Option.map(currentProps.maybeSnapshot, stripOccupationSnapshotImageURL));
              }));
}

var make = memo(function (props) {
      var maybeSnapshot = props.maybeSnapshot;
      if (maybeSnapshot === undefined) {
        return JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "ImageUnavailable"
                                })
                          }),
                      JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "PleaseTryAgainLater"
                                })
                          })
                    ],
                    className: "occupation-details-image-unavailable has-text-centered"
                  });
      }
      var match = Document$LumiStore.contents(maybeSnapshot.snapshot);
      var timestamp = match.timestamp;
      var revRot = RightAngle$LumiDocuments.RightAngle.reverse(match.imgRotation);
      var raabb = (function (__x) {
            return RightAngle$LumiDocuments.RightAngle.applyToRecti(revRot, __x);
          })(maybeSnapshot.placeAABB);
      var viewBox = LumiSVG.ViewBox.Float.fromRect(Rectf$Linear.scaleCenter(Rect$Linear.map(raabb, (function (prim) {
                      return prim;
                    })), 1.02));
      var transform = LumiSVG.Transform.Degree.rotate(RightAngle$LumiDocuments.RightAngle.toDegrees(revRot));
      var match$1 = V2$Linear.map(match.imgResolution, (function (prim) {
              return prim.toString();
            }));
      return JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("header", {
                          children: JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(DateTime$LumiI18n.Full.make, {
                                      date: timestamp
                                    }),
                                className: "card-header-title"
                              }),
                          className: "card-header"
                        }),
                    JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("svg", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsx("image", {
                                            height: match$1[1],
                                            href: maybeSnapshot.imageUrl,
                                            width: match$1[0],
                                            x: "0",
                                            y: "0"
                                          }),
                                      transform: transform
                                    }),
                                className: "occupation-details-image",
                                preserveAspectRatio: "xMidYMid meet",
                                viewBox: viewBox
                              }),
                          className: "card-image"
                        }),
                    JsxRuntime.jsx("footer", {
                          children: JsxRuntime.jsx("p", {
                                children: JsxRuntime.jsx(DateTime$LumiI18n.DistanceToNow.make, {
                                      date: timestamp,
                                      addSuffix: true
                                    }),
                                className: "card-footer-item"
                              }),
                          className: "card-footer"
                        })
                  ],
                  className: "card"
                });
    });

var $$Image = {
  memo: memo,
  make: make
};

function SpotDetails$OccupationDetails(props) {
  var occupationDetails = props.occupationDetails;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(make, {
                                        maybeSnapshot: occupationDetails.oldestSnapshot
                                      }),
                                  className: "tile is-child p-1"
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(make, {
                                        maybeSnapshot: occupationDetails.latestSnapshot
                                      }),
                                  className: "tile is-child p-1"
                                })
                          ],
                          className: "tile is-parent"
                        }),
                    className: "tile is-ancestor"
                  }),
              className: "panel-block"
            });
}

var OccupationDetails = {
  $$Image: $$Image,
  make: SpotDetails$OccupationDetails
};

function memo$1(__x) {
  return React.memo(__x, (function (nextProps, currentProps) {
                return Caml_obj.equal(stripOccupationSnapshotImageURL(nextProps.occupationSnapshot), stripOccupationSnapshotImageURL(currentProps.occupationSnapshot));
              }));
}

var make$1 = memo$1(function (props) {
      var match = props.occupationSnapshot;
      var placeAABB = match.placeAABB;
      var match$1 = Document$LumiStore.contents(match.snapshot);
      var imgResolution = match$1.imgResolution;
      var revRot = RightAngle$LumiDocuments.RightAngle.reverse(match$1.imgRotation);
      var viewBox = LumiSVG.ViewBox.Int.fromRect((function (__x) {
                return RightAngle$LumiDocuments.RightAngle.applyToRecti(revRot, __x);
              })(Recti$Linear.fromSize(imgResolution)));
      var transform = LumiSVG.Transform.Degree.rotate(RightAngle$LumiDocuments.RightAngle.toDegrees(revRot));
      var match$2 = V2$Linear.map(imgResolution, (function (prim) {
              return prim.toString();
            }));
      var match$3 = V2$Linear.map(placeAABB.pos, (function (prim) {
              return prim.toString();
            }));
      var match$4 = V2$Linear.map(placeAABB.size, (function (prim) {
              return prim.toString();
            }));
      return JsxRuntime.jsx("svg", {
                  children: JsxRuntime.jsxs("g", {
                        children: [
                          JsxRuntime.jsx("image", {
                                height: match$2[1],
                                href: match.imageUrl,
                                width: match$2[0],
                                x: "0",
                                y: "0"
                              }),
                          JsxRuntime.jsx("rect", {
                                height: match$4[1],
                                width: match$4[0],
                                fill: "rgba(0,0,0,0)",
                                stroke: "red",
                                strokeWidth: "3",
                                vectorEffect: "non-scaling-stroke",
                                x: match$3[0],
                                y: match$3[1]
                              })
                        ],
                        transform: transform
                      }),
                  className: "is-flex-1",
                  preserveAspectRatio: "xMidYMin meet",
                  viewBox: viewBox
                });
    });

var $$Image$1 = {
  memo: memo$1,
  make: make$1
};

function SpotDetails$OccupationMoreDetails(props) {
  var dispatch = props.dispatch;
  var selectedSnapshot = props.selectedSnapshot;
  var occupationSnapshots = props.occupationSnapshots;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                    name: "image"
                                  }),
                              className: "panel-icon"
                            }),
                        JsxRuntime.jsx("span", {
                              children: JsxRuntime.jsx("select", {
                                    children: occupationSnapshots !== undefined ? occupationSnapshots.map(function (param) {
                                            var snapshot = param.snapshot;
                                            var keyStr = Document$LumiStore.Key.toString(snapshot.key);
                                            return JsxRuntime.jsx("option", {
                                                        children: JsxRuntime.jsx(DateTime$LumiI18n.Full.make, {
                                                              date: Document$LumiStore.contents(snapshot).timestamp
                                                            }),
                                                        value: keyStr
                                                      }, keyStr);
                                          }) : JsxRuntime.jsx("option", {
                                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                  entry: "Loading"
                                                }),
                                            value: ""
                                          }),
                                    value: Core__Option.mapOr(selectedSnapshot, "", (function (param) {
                                            return Document$LumiStore.Key.toString(param.snapshot.key);
                                          })),
                                    onChange: (function (e) {
                                        Core__Option.forEach(Core__Option.map(LumiString.nonEmpty(e.target.value), Document$LumiStore.Key.fromString), (function (occSnapKey) {
                                                dispatch({
                                                      TAG: "SelectSnapshot",
                                                      _0: occSnapKey
                                                    });
                                              }));
                                      })
                                  }),
                              className: "select is-fullwidth"
                            })
                      ],
                      className: "panel-block"
                    }),
                selectedSnapshot !== undefined ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(make$1, {
                              occupationSnapshot: selectedSnapshot
                            }),
                        className: "panel-block"
                      }) : (
                    Core__Option.isSome(occupationSnapshots) ? JsxRuntime.jsx(Panel$Dashboard.Loading.make, {}) : null
                  )
              ]
            });
}

var OccupationMoreDetails = {
  $$Image: $$Image$1,
  make: SpotDetails$OccupationMoreDetails
};

function SpotDetails(props) {
  var toApplication = props.toApplication;
  var sensorState = props.selectedSensorState;
  var facilityMap = props.facilityMap;
  var toAuth = Barrier$LumiAuthentication.Dispatch.useContext();
  var now = LumiReact.CurrentTime.useCurrentTime();
  var maybeLoadOccupationSnapshots = function (param) {
    var state = param.state;
    var occupationDetails = state.selectedOccupation;
    var dispatch = param.dispatch;
    if (occupationDetails !== undefined && !(state.occupationSnapshots !== undefined || !state.moreDetails)) {
      return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getOccupationSnapshots(occupationDetails.occupation.key), (function (x) {
                    dispatch({
                          TAG: "OccupationSnapshotsReceived",
                          _0: x
                        });
                  }), (function (err) {
                    console.error("getOccupationSnapshots failed", err);
                    Barrier$LumiAuthentication.checkServerError(err, toAuth);
                  }));
    }
    
  };
  var match = LumiReactReducer.useReducerWithDependencies({
        sensorState: sensorState
      }, (function (param) {
          var sensorState = param.sensorState;
          return {
                  TAG: "InitWithEffect",
                  _0: {
                    occupationHistory: undefined,
                    selectedOccupation: undefined,
                    occupationSnapshots: undefined,
                    selectedSnapshot: undefined,
                    moreDetails: false
                  },
                  _1: (function (param) {
                      var dispatch = param.dispatch;
                      return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getOccupationHistory(sensorState.sensorKey), (function (history) {
                                    dispatch({
                                          TAG: "OccupationHistoryReceived",
                                          _0: history
                                        });
                                  }), (function (err) {
                                    console.error("getOccupationHistory failed", err);
                                    Barrier$LumiAuthentication.checkServerError(err, toAuth);
                                  }));
                    })
                };
        }), undefined, undefined, undefined, (function (param) {
          var action = param.action;
          var state = param.state;
          if (typeof action !== "object") {
            return {
                    TAG: "Effect",
                    _0: (function (param) {
                        var state = param.state;
                        var match = state.occupationHistory;
                        if (match !== undefined) {
                          var occupationDetails = state.selectedOccupation;
                          if (occupationDetails !== undefined) {
                            var report = JsxRuntime.jsx(AbandonedVehicleReport$Dashboard.make, {
                                  facilityMap: facilityMap,
                                  sensor: match.sensor,
                                  occupationDetails: occupationDetails
                                });
                            toApplication({
                                  TAG: "Print",
                                  _0: report
                                });
                          }
                          
                        }
                        
                      })
                  };
          }
          switch (action.TAG) {
            case "OccupationHistoryReceived" :
                var history = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          occupationHistory: history,
                          selectedOccupation: history.occupations.find(function (details) {
                                return Caml_obj.equal(history.currentOccupation, details.occupation.key);
                              }),
                          occupationSnapshots: state.occupationSnapshots,
                          selectedSnapshot: state.selectedSnapshot,
                          moreDetails: state.moreDetails
                        }
                      };
            case "SelectOccupation" :
                var mbOccKey = action._0;
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          occupationHistory: state.occupationHistory,
                          selectedOccupation: Core__Option.flatMap(state.occupationHistory, (function (param) {
                                  return param.occupations.find(function (details) {
                                              return Caml_obj.equal(mbOccKey, details.occupation.key);
                                            });
                                })),
                          occupationSnapshots: undefined,
                          selectedSnapshot: undefined,
                          moreDetails: state.moreDetails
                        },
                        _1: maybeLoadOccupationSnapshots
                      };
            case "ShowMoreDetails" :
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          occupationHistory: state.occupationHistory,
                          selectedOccupation: state.selectedOccupation,
                          occupationSnapshots: state.occupationSnapshots,
                          selectedSnapshot: state.selectedSnapshot,
                          moreDetails: action._0
                        },
                        _1: maybeLoadOccupationSnapshots
                      };
            case "OccupationSnapshotsReceived" :
                var snapshots = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          occupationHistory: state.occupationHistory,
                          selectedOccupation: state.selectedOccupation,
                          occupationSnapshots: snapshots,
                          selectedSnapshot: snapshots[0],
                          moreDetails: state.moreDetails
                        }
                      };
            case "SelectSnapshot" :
                var occSnapKey = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          occupationHistory: state.occupationHistory,
                          selectedOccupation: state.selectedOccupation,
                          occupationSnapshots: state.occupationSnapshots,
                          selectedSnapshot: Core__Option.flatMap(state.occupationSnapshots, (function (snapshots) {
                                  return snapshots.find(function (param) {
                                              return occSnapKey === param.snapshot.key;
                                            });
                                })),
                          moreDetails: state.moreDetails
                        }
                      };
            
          }
        }));
  var dispatch = match.dispatch;
  var match$1 = match.state;
  var moreDetails = match$1.moreDetails;
  var selectedOccupation = match$1.selectedOccupation;
  var occupationHistory = match$1.occupationHistory;
  var occupied = SensorState$LumiDocuments.occupied(sensorState);
  var tmp;
  if (occupationHistory !== undefined) {
    var name = LumiString.nonEmpty(occupationHistory.sensor.name);
    var tmp$1;
    if (selectedOccupation !== undefined) {
      var isCurrent = Caml_obj.equal(occupationHistory.currentOccupation, selectedOccupation.occupation.key);
      var match$2 = Document$LumiStore.contents(selectedOccupation.occupation);
      var effectiveEndTime = Core__Option.getOr(match$2.endTime, now);
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              isCurrent ? null : JsxRuntime.jsxs("p", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                    name: "triangle-exclamation"
                                  }),
                              className: "panel-icon has-text-warning"
                            }),
                        JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                              entry: "ViewingPreviousOccupation"
                            })
                      ],
                      className: "panel-block"
                    }),
              JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                  name: "stopwatch"
                                }),
                            className: "panel-icon"
                          }),
                      JsxRuntime.jsx(DateTime$LumiI18n.DistanceStrict.make, {
                            date: match$2.beginTime,
                            baseDate: effectiveEndTime,
                            unit: "day",
                            roundingMethod: "floor"
                          })
                    ],
                    className: "panel-block"
                  }),
              JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("a", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "Overview"
                                }),
                            className: LumiReact.classList([[
                                    "is-active",
                                    !moreDetails
                                  ]]),
                            onClick: (function (param) {
                                dispatch({
                                      TAG: "ShowMoreDetails",
                                      _0: false
                                    });
                              })
                          }),
                      JsxRuntime.jsx("a", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "AllImages"
                                }),
                            className: LumiReact.classList([[
                                    "is-active",
                                    moreDetails
                                  ]]),
                            onClick: (function (param) {
                                dispatch({
                                      TAG: "ShowMoreDetails",
                                      _0: true
                                    });
                              })
                          }),
                      JsxRuntime.jsx("a", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "PrintReport"
                                }),
                            onClick: (function (param) {
                                dispatch("PrintAbandonedVehicleReport");
                              })
                          })
                    ],
                    className: "panel-tabs"
                  }),
              moreDetails ? JsxRuntime.jsx(SpotDetails$OccupationMoreDetails, {
                      occupationSnapshots: match$1.occupationSnapshots,
                      selectedSnapshot: match$1.selectedSnapshot,
                      dispatch: dispatch
                    }) : JsxRuntime.jsx(SpotDetails$OccupationDetails, {
                      occupationDetails: selectedOccupation
                    })
            ]
          });
    } else {
      var beginTime = sensorState.changeTime;
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                  name: "stopwatch"
                                }),
                            className: "panel-icon"
                          }),
                      JsxRuntime.jsx(DateTime$LumiI18n.DistanceStrict.make, {
                            date: beginTime,
                            baseDate: now,
                            unit: "day",
                            roundingMethod: "floor"
                          })
                    ],
                    className: "panel-block"
                  }),
              JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                  name: "eye-slash"
                                }),
                            className: "panel-icon"
                          }),
                      JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                            entry: "NoOccupationDetailsAvailable"
                          })
                    ],
                    className: "panel-block"
                  })
            ]
          });
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            name !== undefined ? JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                  name: "tag"
                                }),
                            className: "panel-icon"
                          }),
                      name
                    ],
                    className: "panel-block"
                  }) : null,
            JsxRuntime.jsxs("p", {
                  children: [
                    JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                name: "traffic-light"
                              }),
                          className: LumiReact.classList([
                                [
                                  "panel-icon",
                                  true
                                ],
                                [
                                  "has-text-occupied",
                                  occupied
                                ],
                                [
                                  "has-text-free",
                                  !occupied
                                ]
                              ])
                        }),
                    JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                          entry: occupied ? "OccupiedSince" : "FreeSince"
                        }),
                    JsxRuntime.jsx(DateTime$LumiI18n.Full.make, {
                          date: sensorState.changeTime
                        }),
                    JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(DateTime$LumiI18n.DistanceToNow.make, {
                                date: sensorState.changeTime,
                                addSuffix: true
                              }),
                          className: "tag is-dark"
                        })
                  ],
                  className: "panel-block"
                }),
            JsxRuntime.jsxs("p", {
                  children: [
                    JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                name: "clock-rotate-left"
                              }),
                          className: "panel-icon"
                        }),
                    JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsxs("select", {
                                children: [
                                  occupationHistory.currentOccupation === undefined ? JsxRuntime.jsx("option", {
                                          children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                entry: {
                                                  TAG: "OccupationFromBeginUntilCurrent",
                                                  _0: sensorState.changeTime
                                                }
                                              }),
                                          value: ""
                                        }) : null,
                                  occupationHistory.occupations.map(function (param) {
                                        var occDoc = param.occupation;
                                        var keyStr = Document$LumiStore.Key.toString(occDoc.key);
                                        var occupation = Document$LumiStore.contents(occDoc);
                                        return JsxRuntime.jsx("option", {
                                                    children: Caml_obj.equal(occupationHistory.currentOccupation, occDoc.key) ? JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                            entry: {
                                                              TAG: "OccupationFromBeginUntilCurrent",
                                                              _0: occupation.beginTime
                                                            }
                                                          }) : JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                            entry: {
                                                              TAG: "OccupationFromBeginUntilEnd",
                                                              _0: occupation.beginTime,
                                                              _1: occupation.endTime
                                                            }
                                                          }),
                                                    value: keyStr
                                                  }, keyStr);
                                      })
                                ],
                                value: Core__Option.mapOr(selectedOccupation, "", (function (param) {
                                        return Document$LumiStore.Key.toString(param.occupation.key);
                                      })),
                                onChange: (function (e) {
                                    var mbOccKey = Core__Option.map(LumiString.nonEmpty(e.target.value), Document$LumiStore.Key.fromString);
                                    dispatch({
                                          TAG: "SelectOccupation",
                                          _0: mbOccKey
                                        });
                                  })
                              }),
                          className: "select is-fullwidth"
                        })
                  ],
                  className: "panel-block"
                }),
            tmp$1
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(Panel$Dashboard.Loading.make, {});
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make$2 = SpotDetails;

export {
  stripS3Signature ,
  stripOccupationSnapshotImageURL ,
  OccupationDetails ,
  OccupationMoreDetails ,
  make$2 as make,
}
/* make Not a pure module */
