// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__JSON from "@rescript/core/lib/es6/src/Core__JSON.bs.mjs";
import * as LumiResult from "lumi-rescript/lib/es6/src/LumiResult.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

function fromString(s) {
  return s;
}

function toString(s) {
  return s;
}

function coerce(k) {
  return k;
}

function codec() {
  var encode = function (s) {
    return Jzon.encodeWith(s, Jzon.string);
  };
  var decode = function (json) {
    return Core__Result.map(Jzon.decodeWith(json, Jzon.string), fromString);
  };
  return Jzon.custom(encode, decode);
}

var Key = {
  fromString: fromString,
  toString: toString,
  coerce: coerce,
  codec: codec
};

function codec$1(extCodec, contentCodec) {
  return Jzon.object5((function (param) {
                return [
                        param.deleted,
                        param.labelsHash,
                        param.labels,
                        param.ext,
                        param.contents
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          deleted: param[0],
                          labelsHash: param[1],
                          labels: param[2],
                          ext: param[3],
                          contents: param[4]
                        }
                      };
              }), Jzon.field("deleted", Jzon.bool), Jzon.field("labelsHash", Jzon.nullable(Jzon.string)), Jzon.field("labels", LumiJzon.setString), Jzon.field("ext", extCodec), Jzon.field("contents", contentCodec));
}

function flatMapContents(body, f) {
  return Core__Result.map(f(body.contents), (function (x) {
                return {
                        deleted: body.deleted,
                        labelsHash: body.labelsHash,
                        labels: body.labels,
                        ext: body.ext,
                        contents: x
                      };
              }));
}

function mapContents(body, f) {
  return {
          deleted: body.deleted,
          labelsHash: body.labelsHash,
          labels: body.labels,
          ext: body.ext,
          contents: f(body.contents)
        };
}

var Body = {
  codec: codec$1,
  flatMapContents: flatMapContents,
  mapContents: mapContents
};

function codec$2(extCodec, contentCodec) {
  return Jzon.object3((function (param) {
                return [
                        param.labels,
                        param.ext,
                        param.contents
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          labels: param[0],
                          ext: param[1],
                          contents: param[2]
                        }
                      };
              }), Jzon.field("labels", LumiJzon.setString), Jzon.field("ext", extCodec), Jzon.field("contents", contentCodec));
}

function flatMapContents$1(newBody, f) {
  return Core__Result.map(f(newBody.contents), (function (x) {
                return {
                        labels: newBody.labels,
                        ext: newBody.ext,
                        contents: x
                      };
              }));
}

function mapContents$1(newBody, f) {
  return {
          labels: newBody.labels,
          ext: newBody.ext,
          contents: f(newBody.contents)
        };
}

var NewBody = {
  codec: codec$2,
  flatMapContents: flatMapContents$1,
  mapContents: mapContents$1
};

var codec$3 = Jzon.custom((function (v) {
        return parseFloat(v);
      }), (function (json) {
        var f = Core__JSON.Classify.classify(json);
        if (typeof f === "object" && f.TAG === "Number") {
          return {
                  TAG: "Ok",
                  _0: f._0.toString()
                };
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonType",
                  VAL: [
                    [],
                    "number",
                    json
                  ]
                }
              };
      }));

function toString$1(revNum) {
  return revNum;
}

var RevNum = {
  codec: codec$3,
  toString: toString$1,
  beforeFirst: "0"
};

function codec$4(extCodec, contentCodec) {
  return Jzon.object3((function (param) {
                return [
                        param.revNum,
                        param.createTime,
                        param.body
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          revNum: param[0],
                          createTime: param[1],
                          body: param[2]
                        }
                      };
              }), Jzon.field("revNum", codec$3), Jzon.field("createTime", LumiDate.DateTime.codec), Jzon.field("body", codec$1(extCodec, contentCodec)));
}

function flatMapContents$2(rev, f) {
  return Core__Result.map(flatMapContents(rev.body, f), (function (x) {
                return {
                        revNum: rev.revNum,
                        createTime: rev.createTime,
                        body: x
                      };
              }));
}

var Revision = {
  codec: codec$4,
  flatMapContents: flatMapContents$2
};

function codec$5(extCodec, contentCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.key,
                        param.rev
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          key: param[0],
                          rev: param[1]
                        }
                      };
              }), Jzon.field("key", codec()), Jzon.field("rev", codec$4(extCodec, contentCodec)));
}

function flatMapContents$3(doc, f) {
  return Core__Result.map(flatMapContents$2(doc.rev, f), (function (x) {
                return {
                        key: coerce(doc.key),
                        rev: x
                      };
              }));
}

var $$Document = {
  codec: codec$5,
  flatMapContents: flatMapContents$3
};

function codec$6(tagCodec, valueCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.tag,
                        param.value
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          tag: param[0],
                          value: param[1]
                        }
                      };
              }), Jzon.field("tag", tagCodec), Jzon.field("value", valueCodec));
}

function flatMap(st, f) {
  return Core__Result.map(f(st.value), (function (x) {
                return {
                        tag: st.tag,
                        value: x
                      };
              }));
}

function withTag(value, tag) {
  return {
          tag: tag,
          value: value
        };
}

var SchemaTagged = {
  codec: codec$6,
  flatMap: flatMap,
  withTag: withTag
};

function codec$7(extCodec) {
  return codec$6(Jzon.string, codec$5(extCodec, Jzon.json));
}

function flatMap$1(someDoc, f) {
  return flatMap(someDoc, (function (jsonDoc) {
                return flatMapContents$3(jsonDoc, f);
              }));
}

var SomeDocument = {
  codec: codec$7,
  flatMap: flatMap$1
};

function codec$8(extCodec) {
  return codec$6(Jzon.string, Jzon.array(codec$5(extCodec, Jzon.json)));
}

function asDocuments(x, codec) {
  return LumiResult.sequence(x.value.map(function (jsonDoc) {
                  return flatMapContents$3(jsonDoc, (function (json) {
                                return Jzon.decodeWith(json, codec);
                              }));
                }));
}

var SomeDocuments = {
  codec: codec$8,
  asDocuments: asDocuments
};

function codec$9(extCodec) {
  return codec$6(LumiJzon.tuple2(Jzon.string, Jzon.string), Jzon.array(LumiJzon.tuple2(codec$5(extCodec, Jzon.json), codec$5(extCodec, Jzon.json))));
}

function asDocTup2s(x, codecA, codecB) {
  return LumiResult.sequence(x.value.map(function (param) {
                  return LumiResult.sequence2([
                              flatMapContents$3(param[0], (function (json) {
                                      return Jzon.decodeWith(json, codecA);
                                    })),
                              flatMapContents$3(param[1], (function (json) {
                                      return Jzon.decodeWith(json, codecB);
                                    }))
                            ]);
                }));
}

var SomeDocTup2s = {
  codec: codec$9,
  asDocTup2s: asDocTup2s
};

function codec$10(extCodec) {
  return codec$6(LumiJzon.tuple2(Jzon.string, Jzon.string), Jzon.array(LumiJzon.tuple2(codec$5(extCodec, Jzon.json), Jzon.nullable(codec$5(extCodec, Jzon.json)))));
}

function asDocTup2Maybes(x, codecA, codecB) {
  return LumiResult.sequence(x.value.map(function (param) {
                  return LumiResult.sequence2([
                              flatMapContents$3(param[0], (function (json) {
                                      return Jzon.decodeWith(json, codecA);
                                    })),
                              LumiResult.traverseOption(param[1], (function (jsonDocB) {
                                      return flatMapContents$3(jsonDocB, (function (json) {
                                                    return Jzon.decodeWith(json, codecB);
                                                  }));
                                    }))
                            ]);
                }));
}

var SomeDocTup2Maybes = {
  codec: codec$10,
  asDocTup2Maybes: asDocTup2Maybes
};

function codec$11(extCodec) {
  return codec$6(Jzon.string, codec$1(extCodec, Jzon.json));
}

var SomeBody = {
  codec: codec$11
};

function codec$12(extCodec) {
  return codec$6(Jzon.string, codec$2(extCodec, Jzon.json));
}

var SomeNewBody = {
  codec: codec$12
};

function asContents(x, codec) {
  return LumiResult.sequence(x.value.map(function (json) {
                  return Jzon.decodeWith(json, codec);
                }));
}

var SomeContents = {
  asContents: asContents
};

function toDoc($$document) {
  return {
          key: $$document.key,
          contents: $$document.rev.body.contents
        };
}

function contents($$document) {
  return $$document.rev.body.contents;
}

export {
  Key ,
  Body ,
  NewBody ,
  RevNum ,
  Revision ,
  $$Document ,
  SchemaTagged ,
  SomeDocument ,
  SomeDocuments ,
  SomeDocTup2s ,
  SomeDocTup2Maybes ,
  SomeBody ,
  SomeNewBody ,
  SomeContents ,
  toDoc ,
  contents ,
}
/* codec Not a pure module */
