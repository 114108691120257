// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";

function toRadians(a) {
  return a / (180 / Math.PI);
}

var codec = Jzon.$$float;

export {
  toRadians ,
  codec ,
}
/* No side effect */
