// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function combineEffects(effects, t) {
  var cleanupFunctions = Core__Array.filterMap(effects, (function (effect) {
          return effect(t);
        }));
  if (cleanupFunctions.length > 0) {
    return (function () {
              cleanupFunctions.forEach(function (cleanup) {
                    cleanup();
                  });
            });
  }
  
}

function ignoreCancel(effect, t) {
  effect(t);
}

function noEffect(param) {
  
}

function decomposeInit(init) {
  if (init.TAG === "Init") {
    return [
            init._0,
            []
          ];
  } else {
    return [
            init._0,
            [init._1]
          ];
  }
}

function decomposeUpdate(update, previousState) {
  if (typeof update !== "object") {
    return [
            previousState,
            []
          ];
  }
  switch (update.TAG) {
    case "Update" :
        return [
                update._0,
                []
              ];
    case "UpdateWithEffect" :
        return [
                update._0,
                [update._1]
              ];
    case "Effect" :
        return [
                previousState,
                [update._0]
              ];
    
  }
}

function initToUpdate(init) {
  if (init.TAG === "Init") {
    return {
            TAG: "Update",
            _0: init._0
          };
  } else {
    return {
            TAG: "UpdateWithEffect",
            _0: init._0,
            _1: init._1
          };
  }
}

function useReducerWithDependencies(dependencies, initState, dependenciesEqualOpt, handleDependenciesChangedOpt, logDebugWithTag, handleAction) {
  var dependenciesEqual = dependenciesEqualOpt !== undefined ? dependenciesEqualOpt : Caml_obj.equal;
  var handleDependenciesChanged = handleDependenciesChangedOpt !== undefined ? handleDependenciesChangedOpt : (function (param, param$1) {
        return "Reinit";
      });
  var cleanupEffects = React.useRef([]);
  var runCleanupEffects = function () {
    cleanupEffects.current.forEach(function (cleanup) {
          cleanup();
        });
    cleanupEffects.current = [];
  };
  var match = React.useReducer((function (fullState, fullAction) {
          var logDebugWithTag = fullState.logDebugWithTag;
          var queuedEffects = fullState.queuedEffects;
          var applicationState = fullState.applicationState;
          var applicationDependencies = fullState.applicationDependencies;
          if (fullAction.TAG === "ApplicationAction") {
            var action = fullAction._0;
            var update = handleAction({
                  dependencies: applicationDependencies,
                  state: applicationState,
                  action: action
                });
            var match = decomposeUpdate(update, applicationState);
            var effects = match[1];
            var state = match[0];
            Core__Option.forEach(logDebugWithTag, (function (__x) {
                    console.log(__x, "Action received", action, [
                          "state",
                          applicationState,
                          state
                        ], [
                          "effects",
                          effects
                        ]);
                  }));
            return {
                    applicationDependencies: fullState.applicationDependencies,
                    applicationState: state,
                    initialEffects: fullState.initialEffects,
                    queuedEffects: {
                      contents: queuedEffects.contents.concat(effects)
                    },
                    logDebugWithTag: fullState.logDebugWithTag
                  };
          }
          var dependencies = fullAction._0;
          if (dependenciesEqual(dependencies, applicationDependencies)) {
            return fullState;
          }
          var reinit = handleDependenciesChanged(applicationDependencies, dependencies);
          var match$1;
          if (typeof reinit !== "object") {
            match$1 = [
              decomposeInit(initState(dependencies)),
              true
            ];
          } else if (reinit.TAG === "ReinitAndMergeState") {
            var match$2 = decomposeInit(initState(dependencies));
            match$1 = [
              [
                reinit._0(applicationState, match$2[0]),
                match$2[1]
              ],
              true
            ];
          } else {
            match$1 = [
              decomposeUpdate(handleAction({
                        dependencies: dependencies,
                        state: applicationState,
                        action: reinit._0
                      }), applicationState),
              false
            ];
          }
          var match$3 = match$1[0];
          var effects$1 = match$3[1];
          var state$1 = match$3[0];
          Core__Option.forEach(logDebugWithTag, (function (__x) {
                  console.log(__x, "Dependencies changed", [
                        "dependencies",
                        applicationDependencies,
                        dependencies
                      ], [
                        "state",
                        applicationState,
                        state$1
                      ], [
                        "effects",
                        effects$1
                      ]);
                }));
          if (match$1[1]) {
            Core__Option.forEach(logDebugWithTag, (function (__x) {
                    console.log(__x, "Running effects cleanup on reinit");
                  }));
            runCleanupEffects();
          }
          return {
                  applicationDependencies: dependencies,
                  applicationState: state$1,
                  initialEffects: fullState.initialEffects,
                  queuedEffects: {
                    contents: queuedEffects.contents.concat(effects$1)
                  },
                  logDebugWithTag: fullState.logDebugWithTag
                };
        }), undefined, (function () {
          var init = initState(dependencies);
          Core__Option.forEach(logDebugWithTag, (function (__x) {
                  console.log(__x, "Initialized", init);
                }));
          var match = decomposeInit(init);
          return {
                  applicationDependencies: dependencies,
                  applicationState: match[0],
                  initialEffects: match[1],
                  queuedEffects: {
                    contents: []
                  },
                  logDebugWithTag: logDebugWithTag
                };
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var queuedEffects = match$1.queuedEffects;
  var initialEffects = match$1.initialEffects;
  React.useEffect((function () {
          dispatch({
                TAG: "DependenciesChanged",
                _0: dependencies
              });
        }), [dependencies]);
  var applicationDispatch = React.useCallback((function (action) {
          dispatch({
                TAG: "ApplicationAction",
                _0: action
              });
        }), [dispatch]);
  var reducer_dependencies = match$1.applicationDependencies;
  var reducer_state = match$1.applicationState;
  var reducer = {
    dependencies: reducer_dependencies,
    state: reducer_state,
    dispatch: applicationDispatch
  };
  React.useEffect((function () {
          var cleanupFunctions = Core__Array.filterMap(initialEffects, (function (effect) {
                  return effect(reducer);
                }));
          Caml_splice_call.spliceObjApply(cleanupEffects.current, "push", [cleanupFunctions]);
        }), []);
  React.useEffect((function () {
          if (queuedEffects.contents.length > 0) {
            var effectsToRun = queuedEffects.contents.slice();
            queuedEffects.contents = [];
            var cleanupFunctions = Core__Array.filterMap(effectsToRun, (function (effect) {
                    return effect(reducer);
                  }));
            Caml_splice_call.spliceObjApply(cleanupEffects.current, "push", [cleanupFunctions]);
          }
          
        }), [queuedEffects]);
  React.useEffect((function () {
          Core__Option.forEach(logDebugWithTag, (function (__x) {
                  console.log(__x, "Component mounted");
                }));
          return (function () {
                    Core__Option.forEach(logDebugWithTag, (function (__x) {
                            console.log(__x, "Component unmounted; cleaning up effects");
                          }));
                    runCleanupEffects();
                  });
        }), []);
  return reducer;
}

function useReducer(initState, logDebugWithTag, handleAction) {
  var match = useReducerWithDependencies(undefined, initState, undefined, undefined, logDebugWithTag, (function (param) {
          return handleAction(param.state, param.action);
        }));
  return [
          match.state,
          match.dispatch
        ];
}

export {
  combineEffects ,
  ignoreCancel ,
  noEffect ,
  decomposeInit ,
  decomposeUpdate ,
  initToUpdate ,
  useReducerWithDependencies ,
  useReducer ,
}
/* react Not a pure module */
