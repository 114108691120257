// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as LumiResult from "lumi-rescript/lib/es6/src/LumiResult.bs.mjs";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Core__String from "@rescript/core/lib/es6/src/Core__String.bs.mjs";

function codec(valueCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.start,
                        param.end
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          start: param[0],
                          end: param[1]
                        }
                      };
              }), Jzon.field("start", valueCodec), Jzon.field("end", valueCodec));
}

var $$Range = {
  codec: codec
};

var codec$1 = Jzon.custom((function (value) {
        var tmp;
        switch (value) {
          case "Monday" :
              tmp = "monday";
              break;
          case "Tuesday" :
              tmp = "tuesday";
              break;
          case "Wednesday" :
              tmp = "wednesday";
              break;
          case "Thursday" :
              tmp = "thursday";
              break;
          case "Friday" :
              tmp = "friday";
              break;
          case "Saturday" :
              tmp = "saturday";
              break;
          case "Sunday" :
              tmp = "sunday";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      var other = str.toLowerCase();
                      switch (other) {
                        case "friday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Friday"
                                  };
                        case "monday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Monday"
                                  };
                        case "saturday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Saturday"
                                  };
                        case "sunday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Sunday"
                                  };
                        case "thursday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Thursday"
                                  };
                        case "tuesday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Tuesday"
                                  };
                        case "wednesday" :
                            return {
                                    TAG: "Ok",
                                    _0: "Wednesday"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      other
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var DayOfWeek = {
  codec: codec$1
};

function secondsToString(seconds, maxPrecision) {
  var trailing = /\.?0+$/;
  var str = seconds.toFixed(maxPrecision);
  var match = Core__String.indexOfOpt(str, ".");
  return (
            match === 1 ? "0" + str : str
          ).replace(trailing, "");
}

function toString(param) {
  var hourS = param.hour.toString().padStart(2, "0");
  var minS = param.min.toString().padStart(2, "0");
  var secS = secondsToString(param.sec, 3);
  var secSuffix = secS === "00" ? "" : ":" + secS;
  return hourS + ":" + minS + secSuffix;
}

var regexp = /^(\d{2}):(\d{2})(?::(\d+(?:\.\d+)?))?$/;

function fromString(s) {
  var result = regexp.exec(s);
  if (result == null) {
    return {
            TAG: "Error",
            _0: "invalid format"
          };
  }
  var m = result.slice(1);
  var hour = LumiOption.okOr(Core__Int.fromString(10, m[0]), "invalid hours part");
  var min = LumiOption.okOr(Core__Int.fromString(10, m[1]), "invalid minutes part");
  var secS = m[2];
  var sec = secS !== undefined ? LumiOption.okOr(Core__Float.fromString(secS), "invalid seconds part") : ({
        TAG: "Ok",
        _0: 0
      });
  return Core__Result.map(LumiResult.sequence3([
                  hour,
                  min,
                  sec
                ]), (function (param) {
                return {
                        hour: param[0],
                        min: param[1],
                        sec: param[2]
                      };
              }));
}

var codec$2 = Jzon.custom((function (value) {
        return Jzon.encodeWith(toString(value), Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (s) {
                      return Core__Result.mapError(fromString(s), (function (msg) {
                                    return {
                                            NAME: "SyntaxError",
                                            VAL: msg
                                          };
                                  }));
                    }));
      }));

var TimeOfDay = {
  toString: toString,
  regexp: regexp,
  fromString: fromString,
  codec: codec$2
};

var codec$3 = Jzon.object2((function (param) {
        return [
                param.day,
                param.time
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  day: param[0],
                  time: param[1]
                }
              };
      }), Jzon.field("day", codec$1), Jzon.field("time", codec$2));

var TimeOfWeek = {
  codec: codec$3
};

function fromDate(d) {
  return {
          year: d.getFullYear(),
          month: d.getMonth() + 1 | 0,
          day: d.getDate(),
          hour: d.getHours(),
          minute: d.getMinutes(),
          second: d.getSeconds() + d.getMilliseconds() / 1000
        };
}

var codec$4 = Jzon.custom((function (v) {
        var year = v.year.toString().padStart(4, "0");
        var month = v.month.toString().padStart(2, "0");
        var day = v.day.toString().padStart(2, "0");
        var hour = v.hour.toString().padStart(2, "0");
        var minute = v.minute.toString().padStart(2, "0");
        var second = secondsToString(v.second, 3);
        return Jzon.encodeWith(year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (s) {
                      return {
                              TAG: "Ok",
                              _0: fromDate(new Date(s))
                            };
                    }));
      }));

var LocalTime = {
  fromDate: fromDate,
  codec: codec$4
};

var codec$5 = LumiJzon.taggedVariant((function (value) {
        switch (value.TAG) {
          case "AbsolutePeriodDef" :
              return [
                      "AbsolutePeriodDef",
                      Jzon.encodeWith(value._0, codec(codec$4))
                    ];
          case "WeeklyPeriodDef" :
              return [
                      "WeeklyPeriodDef",
                      Jzon.encodeWith(value._0, codec(codec$3))
                    ];
          case "DailyPeriodDef" :
              return [
                      "DailyPeriodDef",
                      Jzon.encodeWith(value._0, codec(codec$2))
                    ];
          
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "AbsolutePeriodDef" :
              return Core__Result.map(Jzon.decodeWith(json, codec(codec$4)), (function (absolutePeriod) {
                            return {
                                    TAG: "AbsolutePeriodDef",
                                    _0: absolutePeriod
                                  };
                          }));
          case "DailyPeriodDef" :
              return Core__Result.map(Jzon.decodeWith(json, codec(codec$2)), (function (dailyPeriod) {
                            return {
                                    TAG: "DailyPeriodDef",
                                    _0: dailyPeriod
                                  };
                          }));
          case "WeeklyPeriodDef" :
              return Core__Result.map(Jzon.decodeWith(json, codec(codec$3)), (function (weeklyPeriod) {
                            return {
                                    TAG: "WeeklyPeriodDef",
                                    _0: weeklyPeriod
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var PeriodDef = {
  codec: codec$5
};

function codec$6(stateCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.state,
                        param.def
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          state: param[0],
                          def: param[1]
                        }
                      };
              }), Jzon.field("state", stateCodec), Jzon.field("def", codec$5));
}

var Period = {
  codec: codec$6
};

function codec$7(timeZoneCodec, stateCodec) {
  return Jzon.object3((function (param) {
                return [
                        param.defaultState,
                        param.timeZone,
                        param.periods
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          defaultState: param[0],
                          timeZone: param[1],
                          periods: param[2]
                        }
                      };
              }), Jzon.field("defaultState", stateCodec), Jzon.field("timeZone", timeZoneCodec), Jzon.field("periods", Jzon.array(codec$6(stateCodec))));
}

var Timeline = {
  codec: codec$7
};

export {
  $$Range ,
  DayOfWeek ,
  secondsToString ,
  TimeOfDay ,
  TimeOfWeek ,
  LocalTime ,
  PeriodDef ,
  Period ,
  Timeline ,
}
/* codec Not a pure module */
