// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V3$Linear from "./V3.bs.mjs";

function fill(v) {
  return V3$Linear.fill(V3$Linear.fill(v));
}

function map(mat, f) {
  return V3$Linear.map(mat, (function (__x) {
                return V3$Linear.map(__x, f);
              }));
}

function transpose(param) {
  var match = param[2];
  var match$1 = param[1];
  var match$2 = param[0];
  return [
          [
            match$2[0],
            match$1[0],
            match[0]
          ],
          [
            match$2[1],
            match$1[1],
            match[1]
          ],
          [
            match$2[2],
            match$1[2],
            match[2]
          ]
        ];
}

function codec(elementCodec) {
  return V3$Linear.codec(V3$Linear.codec(elementCodec));
}

export {
  fill ,
  map ,
  transpose ,
  codec ,
}
/* No side effect */
