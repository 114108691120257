// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

function option_of_result(res) {
  return Core__Result.mapOr(res, undefined, (function (x) {
                return Caml_option.some(x);
              }));
}

function getItemJSON(key, codec, $$default) {
  return Core__Option.getOr(Core__Option.flatMap(Caml_option.nullable_to_opt(localStorage.getItem(key)), (function (str) {
                    return option_of_result(Jzon.decodeString(codec, str));
                  })), $$default);
}

function setItemJSON(key, codec, value) {
  var __x = Jzon.encodeStringWith(value, codec);
  localStorage.setItem(key, __x);
}

export {
  option_of_result ,
  getItemJSON ,
  setItemJSON ,
}
/* No side effect */
