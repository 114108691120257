// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as React from "react";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as LumiString from "lumi-rescript/lib/es6/src/LumiString.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "./Document.bs.mjs";

function Make(Params) {
  var documentName = Params.documentName;
  var makeDocInfo = function (doc) {
    return {
            key: doc.key,
            name: documentName(Document$LumiStore.contents(doc))
          };
  };
  var cmp = function (a, b) {
    return Caml.string_compare(Document$LumiStore.Key.toString(a), Document$LumiStore.Key.toString(b));
  };
  var CmpKey = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var Schema$Make$DocInfo = function (props) {
    var __copyableKey = props.copyableKey;
    var __showKey = props.showKey;
    var onClick = props.onClick;
    var __className = props.className;
    var match = props.docInfo;
    var key = match.key;
    var className = __className !== undefined ? __className : "is-info";
    var showKey = __showKey !== undefined ? __showKey : true;
    var copyableKey = __copyableKey !== undefined ? __copyableKey : true;
    var name = Core__Option.flatMap(match.name, LumiString.nonEmpty);
    var match$1 = React.useState(function () {
          return false;
        });
    var setKeyCopied = match$1[1];
    var keyCopied = match$1[0];
    var copyKey = function ($$event) {
      $$event.stopPropagation();
      var str = Document$LumiStore.Key.toString(key);
      window.navigator.clipboard.writeText(str).then(function () {
            setKeyCopied(function (param) {
                  return true;
                });
            return setTimeout((function () {
                          setKeyCopied(function (param) {
                                return false;
                              });
                        }), 500);
          });
    };
    return JsxRuntime.jsxs("span", {
                children: [
                  JsxRuntime.jsx("span", {
                        children: name !== undefined ? name : Params.icon,
                        className: LumiReact.classList([
                              [
                                "tag",
                                true
                              ],
                              [
                                className,
                                true
                              ],
                              [
                                "is-clickable",
                                Core__Option.isSome(onClick)
                              ]
                            ]),
                        onClick: onClick
                      }),
                  JsxRuntime.jsx("span", {
                        children: JsxRuntime.jsx("span", {
                              children: Document$LumiStore.Key.toString(key)
                            }),
                        className: LumiReact.classList([
                              [
                                "tag",
                                true
                              ],
                              [
                                "is-light",
                                true
                              ],
                              [
                                "is-family-code",
                                true
                              ],
                              [
                                "is-clickable",
                                Core__Option.isSome(onClick)
                              ],
                              [
                                "is-hidden",
                                !showKey && Core__Option.isSome(name)
                              ]
                            ]),
                        onClick: onClick
                      }),
                  copyableKey ? JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                name: "copy"
                              }),
                          className: LumiReact.classList([
                                [
                                  "tag",
                                  true
                                ],
                                [
                                  "is-light",
                                  !keyCopied
                                ],
                                [
                                  "is-success",
                                  keyCopied
                                ]
                              ]),
                          style: {
                            cursor: "copy"
                          },
                          onClick: copyKey
                        }) : null
                ],
                className: "tags has-addons",
                title: Params.tag
              });
  };
  var DocInfo = {
    make: Schema$Make$DocInfo
  };
  return {
          documentName: documentName,
          tag: Params.tag,
          codec: Params.codec,
          icon: Params.icon,
          makeDocInfo: makeDocInfo,
          CmpKey: CmpKey,
          DocInfo: DocInfo
        };
}

export {
  Make ,
}
/* react Not a pure module */
